// SPDX-License-Identifier: Apache-2.0

import React from "react"
import { utils } from "ethers"
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap"
import { PrimaryButton, ReturnButton } from "./button"
import * as logger from "./types/logger"

interface prop {
  onAbort: () => void
  onSubmit: (amount: string) => void
}

export function DepositWidget(props: prop): JSX.Element {
  const [amount, setAmount] = React.useState("")
  const [valid, setValid] = React.useState(true)
  const validateEtherInput = (val: string): boolean => {
    try {
      utils.parseEther(val)
      return true
    } catch (reason) {
      logger.LogErrorWithField("DepositWidget")(reason)
      return false
    }
  }
  return (
    <>
      <Container className="justify-contents-center" fluid>
        <p>
          You are about to deposit funds to the Erdstall-Contract to either{" "}
          <b>enter</b> the system or <b>increase</b> your existing funds.
        </p>
        <Row className="p-1">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Enter Deposit-Amount:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="string"
                name="DepositAmount"
                isInvalid={amount === "" ? undefined : !valid}
                isValid={amount === "" ? undefined : valid}
                onChange={(ev) => {
                  const amount = ev.target.value
                  setAmount(amount)
                  setValid(validateEtherInput(amount))
                }}
              />
              <Form.Control.Feedback tooltip={true} type="invalid">
                invalid deposit amount
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <Container className="justify-content-center" fluid>
        <Row className="justify-content-end" noGutters>
          <Col className="m-1" md="auto">
            <ReturnButton onClick={props.onAbort}>Abort</ReturnButton>
          </Col>
          <Col className="m-1" md="auto">
            <PrimaryButton
              onClick={() => {
                props.onSubmit(amount)
                setAmount("0")
              }}
            >
              Submit
            </PrimaryButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}
