"use strict"

import { Signer } from "ethers"
import { Erdstall } from "../abi/Erdstall"
import { ERC20__factory } from "../abi/factories/ERC20__factory"

interface TokenRegisteredEvent {
  token: string
  tokenType: string
  tokenHolder: string
}

interface Responder {
  symbol(): Promise<string>
}

export async function QueryRegisteredTokens(
  contract: Erdstall,
  from: number
): Promise<TokenRegisteredEvent[]> {
  const filter = contract.filters.TokenRegistered(null, null, null)
  return contract.queryFilter(filter, from).then((ev) => {
    return ev.map((entry) => {
      return {
        token: entry.args.token,
        tokenType: entry.args.tokenType,
        tokenHolder: entry.args.tokenHolder,
      }
    })
  })
}

export async function FindRegisteredTokenWithSymbol(
  contract: Erdstall,
  from: number,
  symbol: string
): Promise<string> {
  const registeredTokens = await QueryRegisteredTokens(contract, from)
  let filteredToken: string | undefined
  for (const ev of registeredTokens) {
    try {
      const token = ResolveTokenType(contract.signer, ev.tokenType, ev.token)
      const sym = await token.symbol()
      if (sym === symbol) filteredToken = ev.token
    } catch {
      continue
    }
  }

  if (!filteredToken) {
    throw Error("no registered token with the given symbol found")
  }
  return filteredToken
}

function ResolveTokenType(
  signer: Signer,
  ttype: string,
  token: string
): Responder {
  switch (ttype) {
    case "ERC20":
      return ERC20__factory.connect(token, signer)
    default:
      throw Error("not implemented")
  }
}
