import { ShepherdOptionsWithType, ShepherdButtonWithType } from "react-shepherd"
import Shepherd from "shepherd.js"

export default class ESStep {
  private step: ShepherdOptionsWithType

  constructor(step: ShepherdOptionsWithType | undefined) {
    if (step === undefined) {
      this.step = {}
    } else {
      this.step = step
    }
  }

  Build = (): ShepherdOptionsWithType => {
    return this.step
  }

  Id = (id: string): ESStep => {
    this.step.id = id
    return this
  }

  AttachTo = (attachTo: Shepherd.Step.StepOptionsAttachTo): ESStep => {
    this.step.attachTo = attachTo
    return this
  }

  Buttons = (
    buttons: ReadonlyArray<
      Shepherd.Step.StepOptionsButton | ShepherdButtonWithType
    >
  ): ESStep => {
    this.step.buttons = buttons
    return this
  }

  AdvanceOn = (advanceOn: Shepherd.Step.StepOptionsAdvanceOn): ESStep => {
    this.step.advanceOn = advanceOn
    return this
  }

  Arrow = (arrow: boolean): ESStep => {
    this.step.arrow = arrow
    return this
  }

  BeforeShowPromise = (beforeShowPromise: () => Promise<any>): ESStep => {
    this.step.beforeShowPromise = beforeShowPromise
    return this
  }

  Delay = (ms: number): ESStep => {
    this.step.beforeShowPromise = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(undefined)
        }, ms)
      })
    }
    return this
  }

  CancelIcon = (cancelIcon: Shepherd.Step.StepOptionsCancelIcon): ESStep => {
    this.step.cancelIcon = cancelIcon
    return this
  }

  CanClickTarget = (canClickTarget: boolean): ESStep => {
    this.step.canClickTarget = canClickTarget
    return this
  }

  Classes = (classes: string): ESStep => {
    this.step.classes = classes
    return this
  }

  ModalOverlayOpeningPadding = (modalOverlayOpeningPadding: number): ESStep => {
    this.step.modalOverlayOpeningPadding = modalOverlayOpeningPadding
    return this
  }

  ModalOverlayOpeningRadius = (modalOverlayOpeningRadius: number): ESStep => {
    this.step.modalOverlayOpeningRadius = modalOverlayOpeningRadius
    return this
  }

  PopperOptions = (popperOptions: object): ESStep => {
    this.step.popperOptions = popperOptions
    return this
  }

  ScrollTo = (scrollTo: boolean | ScrollIntoViewOptions): ESStep => {
    this.step.scrollTo = scrollTo
    return this
  }

  ScrollToHandler = (
    scrollToHandler: (element: HTMLElement) => void
  ): ESStep => {
    this.step.scrollToHandler = scrollToHandler
    return this
  }

  ShowOn = (showOn: () => boolean): ESStep => {
    this.step.showOn = showOn
    return this
  }

  Text = (
    text:
      | string
      | ReadonlyArray<string>
      | HTMLElement
      | (() => string | ReadonlyArray<string> | HTMLElement)
  ): ESStep => {
    this.step.text = text
    return this
  }

  Title = (title: string | (() => string)): ESStep => {
    this.step.title = title
    return this
  }

  When = (when: Shepherd.Step.StepOptionsWhen): ESStep => {
    this.step.when = when
    return this
  }
}
