// SPDX-License-Identifier: Apache-2.0

// Transaction describes the transactions within Erdstall.
import { Epoch } from "./epoch"
import { utils, Signer } from "ethers"
import { EncodeTransfer, EncodeExitRequest } from "./encode"

export enum TXStatus {
  Transit = "🚚",
  Confirmed = "✅",
  Rejected = "❌",
}

export enum Direction {
  Left,
  Right,
}

// TODO: implement EIP712 for MM s.t. human readable strings are presented to
// the user when signing.

export interface ExitRequest {
  nonce: bigint
  epoch: Epoch
  sender: string
  sig: string
}

export interface Transfer {
  nonce: bigint
  epoch: Epoch
  sender: string
  sig: string

  recipient: string
  amount: bigint
}

export interface MetaTX {
  tx: Transfer
  status: TXStatus
  msg?: string
}

export const SignTransfer = async (
  contract: string,
  tx: Transfer,
  signer: Signer
): Promise<string> => {
  return sign(contract, EncodeTransfer, tx, signer)
}

export const SignExitRequest = async (
  contract: string,
  er: ExitRequest,
  signer: Signer
): Promise<string> => {
  return sign(contract, EncodeExitRequest, er, signer)
}

function sign<T>(
  contract: string,
  encoder: (contract: string, obj: T) => string,
  obj: T,
  signer: Signer
): Promise<string> {
  const msg = encoder(contract, obj)
  const data = utils.keccak256(msg)
  return signer.signMessage(utils.arrayify(data))
}
