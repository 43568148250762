// SPDX-License-Identifier: Apache-2.0

import { Button } from "react-bootstrap"

interface prop {
  onClick: () => void
  children?: any
  disabled?: boolean
}

export function PrimaryButton(props: prop) {
  return (
    <Button
      className="es-primary-button"
      disabled={props.disabled}
      variant="light"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export function ReturnButton(props: prop) {
  return (
    <Button
      className="es-return-button"
      variant="danger"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}
