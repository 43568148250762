// SPDX-License-Identifier: Apache-2.0

import { Container, Row, Col } from "react-bootstrap"
import { PrimaryButton, ReturnButton } from "./button"

interface prop {
  onAbort: () => void
  onSubmit: () => void
}

export function LeaveWidget(props: prop): JSX.Element {
  return (
    <>
      <Container className="justify-contents-center" fluid>
        <p>
          You are about to leave the Erdstall system and withdraw all your
          funds.
        </p>
      </Container>
      <Container className="justify-content-center" fluid>
        <Row className="justify-content-end" noGutters>
          <Col className="m-1" md="auto">
            <ReturnButton onClick={props.onAbort}>Abort</ReturnButton>
          </Col>
          <Col className="m-1" md="auto">
            <PrimaryButton onClick={props.onSubmit}>Submit</PrimaryButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}
