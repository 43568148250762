// SPDX-License-Identifier: Apache-2.0

import { Epoch } from "./epoch"
// Tracker is providing calculation primitives to access correct PhaseNumbers
// after being initialized with tee parameters.

export class Tracker {
  public readonly EpochDuration: number
  public readonly InitBlock: bigint
  private readonly powDepth: number
  private readonly bufferDepth: number
  constructor(pd: number, ib: bigint, powd: number, bd: number) {
    this.EpochDuration = pd
    this.InitBlock = ib
    this.powDepth = powd
    this.bufferDepth = bd
  }

  // EpochToBlock returns the blocknumber in which the given `Epoch` started.
  EpochToBlock = (ep: Epoch): number => {
    const begin = this.InitBlock + BigInt(this.EpochDuration) * ep
    return Number(begin)
  }

  // DepositEpoch returns the current deposit epoch for the given blocknumber.
  Epoch = (blocknum: bigint): Epoch => {
    return this.epoch(blocknum)
  }

  DepositStartBlock = (epoch: bigint): bigint => {
    return this.InitBlock + epoch * BigInt(this.EpochDuration)
  }

  private epoch(blockNum: bigint): Epoch {
    return (
      (this.verifiedBlock(blockNum) - this.InitBlock) /
      BigInt(this.EpochDuration)
    )
  }

  // Progress returns how far the current phase progressed.
  Progress(blockNum: bigint): number {
    const percentage = (prgs: bigint) => {
      return (Number(prgs) / this.EpochDuration) * 100
    }
    return this.curProgress(blockNum, percentage)
  }

  // IsLastPhaseBlock tells whether this block is the last block of a phase.
  IsLastPhaseBlock = (blockNum: bigint): boolean => {
    const isLastPhaseBlock = (prgs: bigint) => {
      return Number(prgs) === this.EpochDuration - 1
    }
    return this.curProgress(blockNum, isLastPhaseBlock)
  }

  // ProgressWithinEpoch returns how many blocks we already progressed within
  // the current `Epoch`.
  ProgressWithinEpoch(blockNum: bigint): number {
    return this.curProgress(blockNum, Number)
  }

  curProgress<T>(blockNum: bigint, p: (prgs: bigint) => T): T {
    const base = this.verifiedBlock(blockNum) - this.InitBlock
    const progress = base % BigInt(this.EpochDuration)
    return p(progress)
  }

  private verifiedBlock(blockNum: bigint): bigint {
    return blockNum - BigInt(this.powDepth) + BigInt(this.bufferDepth)
  }
}
