// SPDX-License-Identifier: Apache-2.0

export interface Event {
  time: Date
  description: string
  content: JSX.Element
}

export function toEvent(c: JSX.Element, description: string): Event {
  return {
    time: new Date(),
    description: description,
    content: c,
  }
}
