import React from "react"
import Container from "react-bootstrap/Container"
import { ethers, utils } from "ethers"
import { Erdstall } from "./abi/Erdstall"
import { PerunToken } from "./abi/PerunToken"
import { Tracker } from "./types/tracker"
import { Epoch } from "./types/epoch"
import Navbar from "react-bootstrap/Navbar"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { ErdstallClient } from "./client/client"
import { StatusWidget } from "./statuswidget"
import { PhaseMeter } from "./phasemeter"
import { CommandCenter } from "./commandcenter"
import { TXHistory } from "./txhistory"
import { OperatorModal } from "./operatorconfig"
import { InfoModal } from "./erdstallmodal"
import { BalancePane } from "./balancepane"
import * as transaction from "./types/transaction"
import * as logger from "./types/logger"
import * as wire from "./conn/wire"
import * as chain from "./chain"
import * as sdk from "@polycrypt/erdstall"

enum ClientStatus {
  disconnected = "❌ disconnected",
  connected = "🟢 connected",
}

enum OperatorStatus {
  evil = "👿 evil",
  benevolent = "👶 benevolent",
  unknown = "❓ unknown",
}

interface props {
  provider: ethers.providers.Web3Provider
  acc: string
  teeaddr: string
  contract: Erdstall
  token: PerunToken
  tracker: Tracker
  esdk: sdk.Session
}

interface state {
  ostatus: OperatorStatus
  cstatus: ClientStatus
  onChainBals: string
  offChainBals: string
  withdrawableBals: string
  epoch: Epoch
  epochProgress: number
  txHistory: transaction.MetaTX[]
  chainHistory: chain.Event[]
  depositing: boolean
  exited: boolean
  exitedEpoch: Epoch
  withdrawDisabled: boolean
  withdrawVisible: boolean
  showOperatorConfig: boolean
  showInfo: boolean
  infoContent: JSX.Element
}

export class ClientView extends React.Component<props, state> {
  private client: ErdstallClient
  private pendingTX: Map<Epoch, number[]>
  private rpcCalls: Map<string, number>
  constructor(props: props) {
    super(props)

    this.pendingTX = new Map<Epoch, number[]>()
    this.rpcCalls = new Map<string, number>()

    this.client = new ErdstallClient(
      props.contract,
      props.esdk,
      props.acc,
      props.token.address,
      props.provider,
      props.tracker
    )

    this.state = {
      cstatus: ClientStatus.disconnected,
      ostatus: OperatorStatus.benevolent,
      onChainBals: "unknown",
      offChainBals: "unknown",
      withdrawableBals: "0",
      epoch: 0n,
      exitedEpoch: 0n,
      epochProgress: 0,
      txHistory: [],
      chainHistory: [],
      exited: true,
      depositing: false,
      withdrawDisabled: true,
      withdrawVisible: false,
      showOperatorConfig: false,
      showInfo: false,
      infoContent: <></>,
    }
  }

  componentDidMount() {
    document.addEventListener("ErdstallConnected", this.onClientConnected)
    document.addEventListener("ErdstallDisconnected", this.onClientDisconnected)
    document.addEventListener("ErdstallMaliciousOp", this.onMaliciousOp)
    document.addEventListener("ErdstallExitNow", this.onExitNow)
    document.addEventListener("ErdstallWithdraw", this.onWithdraw)
    document.addEventListener("ErdstallDeposit", this.onDeposit)
    document.addEventListener("ErdstallBalance", this.onBalance)
    document.addEventListener("ErdstallEpochShift", this.onPhaseShift)
    document.addEventListener("ErdstallProgress", this.onProgress)
    document.addEventListener("ErdstallSentDeposit", this.onSentDeposit)
    document.addEventListener("ErdstallAbortDeposit", this.onAbortDeposit)
    document.addEventListener("ErdstallReceivedDepProof", this.onDepositProof)
    document.addEventListener("ErdstallReceivedBalProof", this.onBalanceProof)
    document.addEventListener("ErdstallTXReceipt", this.onTXReceipt)
    document.addEventListener("ErdstallTXError", this.onTXError)
    document.addEventListener("ErdstallError", this.onError)
    document.addEventListener("ErdstallTX", this.onTX)
    document.addEventListener("ErdstallBalanceUpdate", this.onBalanceUpdate)
    document.addEventListener("ErdstallOnchainEvent", this.onOnchainEvent)
    document.addEventListener(
      "ErdstallReceivedExitProof",
      this.onUnresolvedExitProof
    )
    document.addEventListener("ErdstallBlock", this.onBlock)
    document.addEventListener(
      "ErdstallPopupInformation",
      this.onPopupInformation
    )
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("ErdstallStartTutorial"))
    }, 100)
  }

  componentWillUnmount() {
    this.client.CleanUp()
    document.removeEventListener("ErdstallConnected", this.onClientConnected)
    document.removeEventListener(
      "ErdstallDisconnected",
      this.onClientDisconnected
    )
    document.removeEventListener("ErdstallMaliciousOp", this.onMaliciousOp)
    document.removeEventListener("ErdstallExitNow", this.onExitNow)
    document.removeEventListener("ErdstallWithdraw", this.onWithdraw)
    document.removeEventListener("ErdstallDeposit", this.onDeposit)
    document.removeEventListener("ErdstallBalance", this.onBalance)
    document.removeEventListener("ErdstallEpochShift", this.onPhaseShift)
    document.removeEventListener("ErdstallProgress", this.onProgress)
    document.removeEventListener("ErdstallSentDeposit", this.onSentDeposit)
    document.removeEventListener("ErdstallAbortDeposit", this.onAbortDeposit)
    document.removeEventListener(
      "ErdstallReceivedDepProof",
      this.onDepositProof
    )
    document.removeEventListener(
      "ErdstallReceivedBalProof",
      this.onBalanceProof
    )
    document.removeEventListener("ErdstallTXReceipt", this.onTXReceipt)
    document.removeEventListener(
      "ErdstallReceivedExitProof",
      this.onUnresolvedExitProof
    )
    document.removeEventListener("ErdstallTXError", this.onTXError)
    document.removeEventListener("ErdstallTX", this.onTX)
    document.removeEventListener("ErdstallError", this.onError)
    document.removeEventListener("ErdstallBalanceUpdate", this.onBalanceUpdate)
    document.removeEventListener("ErdstallOnchainEvent", this.onOnchainEvent)
    document.removeEventListener(
      "ErdstallPopupInformation",
      this.onPopupInformation
    )
  }

  private onSentDeposit = () => {
    this.setState({ depositing: true })
  }

  private onAbortDeposit = () => {
    this.setState({ depositing: false })
  }

  private onPopupInformation = (ev: CustomEventInit) => {
    this.setState({
      infoContent: ev.detail!.content,
      showInfo: true,
    })
  }

  private onProgress = (ev: CustomEventInit) => {
    this.setState({ epochProgress: ev.detail })
  }

  private onError = (ev: CustomEventInit) => {
    this.setState({ infoContent: ev.detail, showInfo: true })
  }

  private onUnresolvedExitProof = (ev: CustomEventInit) => {
    const ep = ev.detail.ep as Epoch
    this.setState({
      withdrawVisible: true,
      exitedEpoch: ep,
      withdrawableBals: this.client.WithdrawableBalance(),
    })
    this.confirmPendingTXs(ep)
  }

  private onTXError = (ev: CustomEventInit) => {
    const index = this.rpcCalls.get(ev.detail.id)
    if (index === undefined) {
      return
    }

    this.setState((state) => {
      const txhClone = this.cloneHistory(state.txHistory)
      txhClone[index].status = transaction.TXStatus.Rejected
      txhClone[index].msg = ev.detail.error
      return {
        txHistory: txhClone,
      }
    })
  }

  private onBalanceUpdate = () => {
    console.info("Updating ErdstallBalance...")
    this.setState({
      offChainBals: this.client.OffChainBalance(),
    })
  }

  private onClientConnected = () => {
    this.setState({
      cstatus: ClientStatus.connected,
    })
  }

  private onDepositProof = () => {
    this.setState({
      ostatus: OperatorStatus.benevolent,
      depositing: false,
    })
  }

  private onOnchainEvent = (ev: CustomEventInit) => {
    const chainEv: chain.Event = ev.detail
    this.setState((state) => {
      return {
        chainHistory: state.chainHistory.concat(chainEv),
      }
    })
  }

  private onTX = (ev: CustomEventInit) => {
    const tx: transaction.Transfer = ev.detail.tx
    this.setState((state) => {
      const txhClone = state.txHistory.concat({
        tx,
        status: transaction.TXStatus.Transit,
      })
      const index = txhClone.length - 1
      this.rpcCalls.set(ev.detail.id, index)
      this.appendPendingTX(tx.epoch, index)
      return {
        txHistory: txhClone,
      }
    })
  }

  private onTXReceipt = (ev: CustomEventInit) => {
    const tx: transaction.Transfer = ev.detail

    this.setState((state) => {
      const txhClone = state.txHistory.concat({
        tx,
        status: transaction.TXStatus.Transit,
      })
      const index = txhClone.length - 1
      this.appendPendingTX(tx.epoch, index)
      return {
        txHistory: txhClone,
      }
    })
  }

  private onBalanceProof = (ev: CustomEventInit) => {
    this.confirmPendingTXs(ev.detail.ep)
    this.setState({
      exited: false,
      ostatus: OperatorStatus.benevolent,
    })
  }

  // onBlock has to be called in the front-end periodically, since the client
  // can be out of sync with the enclave, thus not accepting a valid
  // balanceproof it received for balance display purposes.
  private onBlock = () => {
    this.setState({
      offChainBals: this.client.OffChainBalance(),
    })
  }

  private onClientDisconnected = () => {
    this.setState({
      cstatus: ClientStatus.disconnected,
    })
  }

  private onMaliciousOp = () => {
    this.setState({
      ostatus: OperatorStatus.evil,
    })
  }

  private onExitNow = () => {
    this.setState({
      exited: true,
    })
  }

  private onWithdraw = () => {
    this.setState({
      offChainBals: this.client.OffChainBalance(),
      withdrawableBals: this.client.WithdrawableBalance(),
      withdrawVisible: false,
    })
  }

  private onDeposit = () => {
    this.setState({
      exited: false,
      offChainBals: this.client.OffChainBalance(),
    })
  }

  private onBalance = () => {
    this.client.OnChainBalance().then((bal) => {
      this.setState({
        onChainBals: utils.formatUnits(bal, "ether"),
      })
    })
  }

  private onPhaseShift = (ev: CustomEventInit) => {
    this.setState({
      epoch: ev.detail.epoch,
    })

    if (ev.detail.epoch >= this.state.exitedEpoch) {
      this.setState({
        withdrawDisabled: false,
      })
    }
  }

  private drawClientStatus = (): string | undefined => {
    if (this.state.cstatus === ClientStatus.connected) {
      return this.client.Address.toString()
    }
    return undefined
  }

  // appendPendingTX appends the given index to the list of indices for the given
  // epoch.
  private appendPendingTX = (ep: Epoch, index: number) => {
    if (!this.pendingTX.has(ep)) {
      this.pendingTX.set(ep, [index])
      return
    }

    console.log(`APPENDING TX IN EPOCH ${ep}`)

    const is = this.pendingTX.get(ep) as number[]
    is.push(index)
  }

  // confirmPendingTXs confirms all pending transactions, which were not
  // rejected by the operator.
  private confirmPendingTXs = (ep: Epoch) => {
    const confirmTXsFor = (ep: Epoch) => {
      this.setState((state) => {
        const txhClone = this.cloneHistory(state.txHistory)
        const confirmForEpoch = (ep: bigint) => {
          if (!this.pendingTX.has(ep)) {
            return
          }

          this.pendingTX.get(ep)!.forEach((index) => {
            if (txhClone[index].status === transaction.TXStatus.Rejected) {
              return
            }
            txhClone[index].status = transaction.TXStatus.Confirmed
          })
        }
        confirmForEpoch(ep)
        confirmForEpoch(ep - 1n)
        return {
          txHistory: txhClone,
        }
      })
    }

    console.log(`CONFIRM_TXS IN ${ep}`)
    const epochs: Epoch[] = [ep, ep - 1n]
    for (const e of epochs) {
      if (this.pendingTX.has(e)) {
        logger.Log("Confirming TXs...")
        return confirmTXsFor(e)
      }
    }

    logger.Log("There are no pendingTXs for the given epoch...")
  }

  private cloneHistory(txh: transaction.MetaTX[]): transaction.MetaTX[] {
    return JSON.parse(
      JSON.stringify(txh, wire.StringifyBigInt),
      wire.ErdstallReviver
    ) as transaction.MetaTX[]
  }

  private toggleOperatorConfig = () => {
    this.setState(({ showOperatorConfig }) => {
      return { showOperatorConfig: !showOperatorConfig }
    })
  }

  private toggleInfo = () => {
    this.setState(({ showInfo }) => {
      return { showInfo: !showInfo }
    })
  }

  render() {
    const title = "🍵 ERDSTALL"
    return (
      <>
        <Navbar id="esNavBar" className="esNavBar plane" variant="dark">
          <Navbar.Brand href="https://erdstall.dev">{title}</Navbar.Brand>
          <Container fluid>
            <Col className="es-client-status" md="auto">
              <StatusWidget
                label="Client-Status"
                symbol={this.state.cstatus.split(" ")[0]}
                tooltip={this.state.cstatus.split(" ")[1]}
                status={this.drawClientStatus()}
              />
            </Col>
            <Col>
              <BalancePane
                depositing={this.state.depositing}
                onChainBals={this.state.onChainBals}
                offChainBals={this.state.offChainBals}
                withdrawableBals={this.state.withdrawableBals}
              />
            </Col>
            <Col md="auto">
              <Container fluid>
                <StatusWidget
                  id="operator-status"
                  className="justify-content-end"
                  label="Operator-Status"
                  symbol={this.state.ostatus.split(" ")[0]}
                  tooltip={this.state.ostatus.split(" ")[1]}
                  onClick={this.toggleOperatorConfig}
                />
              </Container>
            </Col>
          </Container>
        </Navbar>
        <Container id="es-maincolumn" className="es-maincolumn" fluid>
          <Row className="p-1 commandcenter">
            <CommandCenter
              onSendTX={this.client.OnSendTX}
              onDeposit={this.client.OnDeposit}
              onExit={this.client.OnExit}
              onWithdraw={this.client.OnWithdraw}
              onChainBals={this.state.onChainBals}
              offChainBals={this.state.offChainBals}
              exited={this.state.exited}
              withdrawDisabled={this.state.withdrawDisabled}
              withdrawVisible={this.state.withdrawVisible}
              address={this.client.Address.toString()}
            />
          </Row>
          <Row className="p-1 txhistory">
            <TXHistory
              address={this.client.Address.toString()}
              txs={this.cloneHistory(this.state.txHistory).reverse()}
              chainEvents={this.state.chainHistory}
            />
          </Row>
          <Row className="p-1 phasemeter">
            <PhaseMeter
              epoch={this.state.epoch}
              progress={this.state.epochProgress}
              duration={this.props.tracker.EpochDuration}
            />
          </Row>
        </Container>{" "}
        <OperatorModal
          Erdstall={this.props.contract.address}
          Token={this.props.token.address}
          show={this.state.showOperatorConfig}
          onToggle={this.toggleOperatorConfig}
        />
        <InfoModal
          content={this.state.infoContent}
          show={this.state.showInfo}
          toggleShow={this.toggleInfo}
        />
      </>
    )
  }
}
