// SPDX-License-Identifier: Apache-2.0

import { Balance, BalanceProof } from "../types/balance"
import { Transfer, ExitRequest } from "../types/transaction"

export type Method = "sendTx" | "subscribe" | "sendExit"

export type Topic = "balanceProofs" | "txReceipts"

export type ErdstallObject =
  | Subscription
  | SendTX
  | SendExit
  | Transfer
  | ExitRequest
  | Balance
  | BalanceProof

export type SignedObject = BalanceProof | Transfer

export interface ClientConfig extends Call {
  config: {
    contract: string
    networkID: string
    powDepth: number
  }
}

export interface Call {
  id?: string
  method: Method
}

export interface Subscription extends Call {
  who: string
}

export interface SendTX extends Call {
  tx: Transfer
}

export interface SendExit extends Call {
  tx: ExitRequest
}

export interface TXReceipt extends Call {
  tx: Transfer | ExitRequest
}

export interface OperatorMessage {
  id: string
  proof: BalanceProof
  tx: Transfer
  error: string
  topic: Topic
}

// Encode encodes a given ErdstallObject to json.
export function Encode(obj: ErdstallObject): string {
  return JSON.stringify(obj, StringifyBigInt)
}

// Auxiliary function aiding `JSON.stringify` to handle encoding of `BigInt`.
export function StringifyBigInt(key: string, value: any): any {
  switch (key) {
    case "amount":
      return value.toString()
    case "value":
      return value.toString()
    case "epoch":
      return Number(value as bigint)
    case "nonce":
      return Number(value as bigint)
    default:
      return value
  }
}

// Decode decodes json into an Erdstall struct.
export function Decode(json: string): ErdstallObject {
  return JSON.parse(json, ErdstallReviver)
}

// DecodeMessage decodes a message received from the operator as an `OperatorMessage`.
export function DecodeMessage(json: string): OperatorMessage {
  return JSON.parse(json, ErdstallReviver)
}

// ErdstallReviver is a JSON reviver to correctly restore Erdstall constructs
// from json strings. It mainly enables parsing of stringified `BigInts`.
// It also handles the case, where BigInts might be serialized as `null`. In
// this case `null` will be decoded as `BigInt(0)`.
export function ErdstallReviver(key: string, value: any): any {
  const convertToBI = (val: any): bigint => {
    return val === null ? BigInt(0) : BigInt(val)
  }

  switch (key) {
    case "amount":
      return convertToBI(value)
    case "value":
      return convertToBI(value)
    case "nonce":
      return convertToBI(value)
    case "epoch":
      return convertToBI(value)
    default:
      return value
  }
}
