import Container from "react-bootstrap/Container"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"

interface props {
  text: JSX.Element
}

export function Loader(prop: props) {
  return (
    <Container className="justify-content-center" fluid>
      <Row className="justify-content-center">
        <Spinner
          style={{ width: "200px", height: "200px" }}
          animation="grow"
          variant="info"
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Row>
      <Row className="mt-4 justify-content-center">{prop.text}</Row>
    </Container>
  )
}
