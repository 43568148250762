// SPDX-License-Identifier: Apache-2.0

import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

interface prop {
  onWithdraw: () => void
  onAbort: () => void
  description: JSX.Element
}

export function WithdrawWidget(props: prop) {
  return (
    <>
      <Container className="justify-contents-center">
        {props.description}
      </Container>
      <Container className="mt-2" fluid>
        <Row className="">
          <Col>
            <Button
              variant="success"
              size="lg"
              onClick={props.onWithdraw}
              block
            >
              <b>Submit</b>
            </Button>
            <Button variant="danger" size="lg" onClick={props.onAbort} block>
              <b>Back</b>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
