// SPDX-License-Identifier: Apache-2.0

import React, { Component } from "react"
import { utils, BigNumber, constants } from "ethers"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import * as transaction from "./types/transaction"
import * as chain from "./chain"
import { AutoSizer } from "react-virtualized"
import { List } from "react-virtualized"
import { ListRowProps } from "react-virtualized"
import * as fa from "react-icons/fa"
import * as io from "react-icons/io5"
import * as bi from "react-icons/bi"
import { chainRenderer } from "./chaintable"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Tab from "react-bootstrap/Tab"
import Nav from "react-bootstrap/Nav"

const rowHeight = 30

interface txHistoryProps {
  address: string
  txs: transaction.MetaTX[]
  chainEvents: chain.Event[]
}

interface txHistoryState {
  address: string // current address of user.
}

export class TXHistory extends Component<txHistoryProps, txHistoryState> {
  constructor(props: txHistoryProps) {
    super(props)
    this.state = {
      address: props.address,
    }
  }

  direction = (tx: transaction.MetaTX): transaction.Direction => {
    if (tx.tx.sender === this.state.address) return transaction.Direction.Right
    else return transaction.Direction.Left
  }

  private resolveTxStatus(status: transaction.TXStatus): JSX.Element {
    const style: React.CSSProperties = { position: "absolute", top: "6px" }
    switch (status) {
      case transaction.TXStatus.Confirmed:
        return <io.IoShieldCheckmark style={style} />
      case transaction.TXStatus.Rejected:
        return <bi.BiError color="orange" style={style} />
      case transaction.TXStatus.Transit:
        return <io.IoCheckmark style={style} />
      default:
        return <span>{status}</span>
    }
  }

  private statusWithTooltip = (tx: transaction.MetaTX, key: string) => {
    if (typeof tx.msg === "undefined") {
      return this.resolveTxStatus(tx.status)
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={key}>{tx.msg}</Tooltip>}
      >
        {this.resolveTxStatus(tx.status)}
      </OverlayTrigger>
    )
  }

  rowRenderer = ({ index, key, style }: ListRowProps) => {
    const tx = this.props.txs[index]
    const dir = this.direction(tx)
    return (
      <Row className="txHistoryRow" noGutters key={key} style={style}>
        <Col className="border-right" md="auto">
          <div className="mt-1">
            <b className="ml-1 mr-1">{constants.EtherSymbol}</b>
          </div>
        </Col>
        <Col className="border-right" md="2">
          <Container className="mt-1 text-truncate">
            {utils.formatEther(BigNumber.from(tx.tx.amount))}
          </Container>
        </Col>
        <Col className="justify-content-center border-right" md="1">
          <Container className="d-flex justify-content-center mt-2" fluid>
            {dir === transaction.Direction.Left ? (
              <fa.FaArrowLeft />
            ) : (
              <fa.FaArrowRight />
            )}
          </Container>
        </Col>
        <Col className="border-right" md="1">
          <Container className="d-flex justify-content-center mt-1">
            {this.statusWithTooltip(tx, key)}
          </Container>
        </Col>
        <Col className="justify-content-center" md="auto">
          <Container className="mt-1">
            {dir === transaction.Direction.Left
              ? tx.tx.sender
              : tx.tx.recipient}
          </Container>
        </Col>
        {dir === transaction.Direction.Right ? (
          <Col className="justify-content-end border-left">
            <Button
              className="es-txhistory-button"
              size="sm"
              onClick={(ev) => {
                ev.preventDefault()
                document.dispatchEvent(
                  new CustomEvent("ErdstallRetryTX", { detail: tx.tx })
                )
              }}
              block
            >
              <io.IoReload />
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    )
  }

  render() {
    return (
      <Container className={"erdstallwidget plane White-Text txhistory"}>
        <Tab.Container defaultActiveKey="txhistoryK">
          <Row className="mb-0" noGutters>
            <Col className="mt-3 justify-content-md-center" md="auto">
              <span className="widgetHeader mr-1">
                <io.IoDocumentSharp />
              </span>
            </Col>
            <Col className="mt-1 widgetHeader">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link
                    className="White-Text es-history-header"
                    eventKey="txhistoryK"
                  >
                    TX-History
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="White-Text es-history-header"
                    eventKey="onchainhistory"
                  >
                    Chain-History
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="es-history-table-row mt-2">
            <Tab.Content className="es-tx-table">
              <Tab.Pane className="es-tx-table" eventKey="txhistoryK">
                <Container className="es-history-container">
                  <AutoSizer>
                    {({ height, width }) => {
                      return (
                        <List
                          width={width}
                          height={height}
                          rowHeight={rowHeight}
                          rowRenderer={this.rowRenderer}
                          rowCount={this.props.txs.length}
                          onRowsRendered={() => {}}
                        />
                      )
                    }}
                  </AutoSizer>
                </Container>
              </Tab.Pane>
              <Tab.Pane className="es-tx-table" eventKey="onchainhistory">
                <Container className="es-history-container">
                  <AutoSizer>
                    {({ height, width }) => {
                      return (
                        <List
                          width={width}
                          height={height}
                          rowHeight={rowHeight}
                          rowRenderer={chainRenderer(this.props.chainEvents)}
                          rowCount={this.props.chainEvents.length}
                          onRowsRendered={() => {}}
                        />
                      )
                    }}
                  </AutoSizer>
                </Container>
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Container>
    )
  }
}
