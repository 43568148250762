// SPDX-License-Identifier: Apache-2.0

type etherscanType = "transaction" | "address" | "block"

interface props {
  className?: string
  address: string
  text?: string
  type: etherscanType
}

const baseURL = "https://goerli.etherscan.io"

export function EtherScan(props: props) {
  const constructEtherScanLink = (address: string, t: etherscanType) => {
    switch (t) {
      case "transaction":
        return `${baseURL}/tx/${address}`
      case "address":
        return `${baseURL}/address/${address}`
      case "block":
        return `${baseURL}/block/${address}`
      default:
        return ""
    }
  }

  return (
    <a
      className={props.className}
      href={constructEtherScanLink(props.address, props.type)}
      target="_blank"
    >
      {props.text === undefined ? props.address : props.text}
    </a>
  )
}
