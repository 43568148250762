// SPDX-License-Identifier: Apache-2.0

// RingBuffer is a fixed sized datastructure allowing infinite prepends by
// overriding old entries.
export class RingBuffer<T> {
  private buffer: Array<T>
  private index: number // index is guaranteed to be in [0, size-1].
  private size: number
  constructor(size: number) {
    this.buffer = Array<T>(size)
    this.index = 0
    this.size = size
  }

  // Push pushes `val` into the next spot within the RingBuffer.
  Push(val: T) {
    this.buffer[this.index] = val
    this.incrementIndexBy(1)
  }

  // Head reads the newest value.
  Head(): T | undefined {
    return this.buffer[this.offsetIndexBy(1)]
  }

  // ElementAt retrieves the number at the given `offset`. `offset` is automatically
  // clamped to fit within [0, size-1].
  // Example:
  // `^` is the current newest inserted element.
  // RingBuffer of size 4
  // [3, 2, 1, 5]
  //        ^
  // buf.ElementAt(0) == buf.Head() == 1
  // buf.ElementAt(1) == 2
  // buf.ElementAt(2) == 3
  // buf.ElementAt(3) == 5
  // buf.ElementAt(4) == buf.ElementAt(0) == 1
  ElementAt(offset: number): T | undefined {
    const head = this.offsetIndexBy(1)
    const index = this.offsetValueClamped(head, -offset)
    return this.buffer[index]
  }

  // Clear clears the content of the `RingBuffer`.
  Clear() {
    this.buffer = Array<T>(this.size)
  }

  private incrementIndexBy = (amount: number) => {
    this.index = (this.index + amount) % this.size
  }

  // offsetIndexBy offsets the index by the given `amount` and returns a valid
  // index to the underlying buffer.
  private offsetIndexBy = (amount: number): number => {
    return this.offsetValueClamped(this.index, -amount)
  }

  private offsetValueClamped = (val: number, offset: number): number => {
    const index = val + offset
    if (index < 0) {
      return (index + this.size) % this.size
    } else {
      return index % this.size
    }
  }
}
