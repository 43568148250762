// SPDX-License-Identifier: Apache-2.0

import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { ErdstallInfoExit } from "./text"

interface props {
  onSubmit: () => void
}

export function InfoWidget(props: props) {
  return (
    <>
      <Container className="justify-contents-center">
        {ErdstallInfoExit}
      </Container>
      <Container className="mt-2" fluid>
        <Row className="">
          <Col>
            <Button variant="success" size="lg" onClick={props.onSubmit} block>
              <b>Okay!</b>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
