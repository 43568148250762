import * as chain from "./chain"
import { ListRowProps } from "react-virtualized"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export function chainRenderer(
  chainEvents: chain.Event[]
): (_: ListRowProps) => JSX.Element {
  return ({ index, key, style }: ListRowProps) => {
    const chainEvent = chainEvents[index]
    return (
      <Row className="txHistoryRow" noGutters key={key} style={style}>
        <Col className="border-right justify-content-center" md="3">
          <div className="mt-1">
            <b className="ml-1 mr-1">{chainEvent.time.toLocaleString()}</b>
          </div>
        </Col>
        <Col md="auto">
          <div className="mt-1">
            <b className="ml-1 mr-1">
              {chainEvent.description} {chainEvent.content}
            </b>
          </div>
        </Col>
      </Row>
    )
  }
}
