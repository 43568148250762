// SPDX-License-Identifier: Apache-2.0

import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Loader from "react-loader-spinner"
import * as fa from "react-icons/fa"
import * as bi from "react-icons/bi"

interface props {
  depositing: boolean
  onChainBals: string
  offChainBals: string
  withdrawableBals: string
}

export function BalancePane(props: props) {
  return (
    <Row className="White-Text">
      <Col className="es-onchainbal text-truncate">
        <Row noGutters>
          <Col md="auto">
            <fa.FaEthereum />
          </Col>
          <Col className="ml-1" md="auto">
            On-Chain (PRN):
          </Col>
          <Col className="ml-1">{props.onChainBals}</Col>
        </Row>
      </Col>
      <Col className="es-offchainbal text-truncate">
        <Row noGutters>
          {props.depositing ? (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="wfdp">
                  Waiting for confirmation from Operator...
                </Tooltip>
              }
            >
              <Col md="auto">
                <Loader
                  type="TailSpin"
                  color="#00bfff"
                  height={12}
                  width={12}
                />
              </Col>
            </OverlayTrigger>
          ) : (
            <Col md="auto">🍵</Col>
          )}
          <Col className="ml-1" md="auto">
            Off-Chain (PRN):
          </Col>
          <Col className="ml-1">{props.offChainBals}</Col>
        </Row>
      </Col>
      <Col className="es-withdrawableBals text-truncate">
        <Row noGutters>
          <Col md="auto">
            <bi.BiTransferAlt />
          </Col>
          <Col className="ml-1" md="auto">
            Withdrawable (PRN):
          </Col>
          <Col className="ml-1">{props.withdrawableBals}</Col>
        </Row>
      </Col>
    </Row>
  )
}
