// SPDX-License-Identifier: Apache-2.0

import { Component } from "react"
import { utils } from "ethers"
import ErdstalLWidget from "./erdstallwidget"
import { ethers } from "ethers"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { CircularProgress } from "@material-ui/core"
import { DepositWidget } from "./depositwidget"
import { TxWidget } from "./txwidget"
import { LeaveWidget } from "./leavewidget"
import { WithdrawWidget } from "./withdrawwidget"
import { InfoWidget } from "./infowidget"
import { EtherScan } from "./etherscan"
import { TxReceipt, BalanceProof } from "@polycrypt/erdstall/api/responses"
import { Stages, Stage } from "@polycrypt/erdstall/utils"
import * as fa from "react-icons/fa"
import * as CCloader from "./ccloader"
import * as logger from "./types/logger"
import * as errors from "./types/error"
import * as chain from "./chain"
import * as text from "./text"

const logo = <fa.FaSatellite />

type states =
  | "Choosing"
  | "TXActing"
  | "TopUpActing"
  | "LeaveActing"
  | "Loading"
  | "InfoActing"
  | "WithdrawActing"
  | "WithdrawCommonActing"

interface props {
  onSendTX: (recipient: string, amount: string) => Promise<TxReceipt>
  onExit: () => Promise<BalanceProof>
  onDeposit: (
    amount: string
  ) => Promise<Stages<Promise<ethers.ContractTransaction>>>
  onWithdraw: () => Promise<Stages<Promise<ethers.ContractTransaction>>>
  onChainBals: string
  offChainBals: string
  exited: boolean
  withdrawDisabled: boolean
  withdrawVisible: boolean
  address: string
}

interface state {
  ccState: states
  recipient: string
  recentRecipients: Set<string>
  isValidRecipient: boolean
  statusmsg: JSX.Element
  depositing: boolean
  exiting: boolean
  exitProgress: number
  exitEpoch: bigint
}

export class CommandCenter extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      ccState: "Choosing",
      recipient: "",
      recentRecipients: new Set().add(props.address) as Set<string>,
      isValidRecipient: false,
      exiting: false,
      statusmsg: <></>,
      depositing: false,
      exitProgress: 0,
      exitEpoch: 0n,
    }
  }

  componentDidMount() {
    document.addEventListener(
      "ErdstallReceivedDepProof",
      this.onReceivedDepProof
    )
    document.addEventListener(
      "ErdstallReceivedExitProof",
      this.onReceivedExitProof
    )
    document.addEventListener(
      "ErdstallUpdateExitProgress",
      this.onUpdateExitProgress
    )
    document.addEventListener("ErdstallReset", this.onTutorialReset)
    document.addEventListener("ErdstallExecuteWithdraw", this.withdrawFunds)
    document.addEventListener("ErdstallError", this.onErdstallError)
    document.addEventListener("ErdstallTXReceipt", this.onAddRecipient)
  }

  componentWillUnmount() {
    document.removeEventListener(
      "ErdstallReceivedDepProof",
      this.onReceivedDepProof
    )
    document.removeEventListener(
      "ErdstallReceivedExitProof",
      this.onReceivedExitProof
    )
    document.removeEventListener(
      "ErdstallUpdateExitProgress",
      this.onUpdateExitProgress
    )
    document.removeEventListener("ErdstallReset", this.onTutorialReset)
    document.removeEventListener("ErdstallExecuteWithdraw", this.withdrawFunds)
    document.removeEventListener("ErdstallError", this.onErdstallError)
    document.removeEventListener("ErdstallTXReceipt", this.onAddRecipient)
  }

  onAddRecipient = (ev: CustomEventInit) => {
    const recipient = ev.detail.sender as string
    this.setState((state) => {
      return { recentRecipients: state.recentRecipients.add(recipient) }
    })
  }

  onErdstallError = () => {
    this.setState({ exiting: false })
    this.switchTo("Choosing")
  }

  onTutorialReset = () => {
    this.switchTo("Choosing")
  }

  onUpdateExitProgress = (ev: CustomEventInit) => {
    const progress = ev.detail as number
    console.log(`Progress ExitRequest: ${progress}`)
    this.setState({ exitProgress: progress * 100 })
  }

  onReceivedDepProof = () => {
    this.setState({ depositing: false })
  }

  onReceivedExitProof = (ev: CustomEventInit) => {
    console.log(`Received ExitProof in ${ev.detail.ep}`)
    const ep = ev.detail.ep as bigint
    this.setState({ exitEpoch: ep })
  }

  onAbort = () => {
    this.setState({
      ccState: "Choosing",
      isValidRecipient: false,
    })
  }

  onTXSubmit = (amount: string) => {
    this.props
      .onSendTX(this.state.recipient, amount)
      .then(() => {
        this.switchTo("Choosing")
        document
          .getElementById("es-maincolumn")!
          .dispatchEvent(new CustomEvent("ErdstallOffchainTXSigned"))
      })
      .catch((obj) => {
        const reason = this.parseError(obj)
        this.switchTo("TXActing")
        errors.Erdstall(<span>{reason}</span>)
      })
      .finally(() => {
        this.setState({ isValidRecipient: false })
      })
  }

  onLeaveSubmit = async () => {
    this.switchTo("Loading")
    this.setState({
      statusmsg: <span>Exiting Erdstall, sign transaction to proceed...</span>,
      exiting: true,
    })
    this.props
      .onExit()
      .then(() => {
        this.switchTo("InfoActing")
      })
      .catch((obj) => {
        const reason = this.parseError(obj)
        this.setState({ exiting: false })
        errors.Erdstall(<span>{reason}</span>)
        this.switchTo("Choosing")
      })
    this.setState({
      statusmsg: <span>Exiting Erdstall, waiting for proof to arrive...</span>,
      exiting: true,
    })
  }

  private withdrawFunds = () => {
    this.switchTo("Loading")
    this.setState({
      exitProgress: 0,
      exiting: false,
      statusmsg: <span>Withdrawing funds, sign transaction to proceed...</span>,
    })
    this.props
      .onWithdraw()
      .then(async (stages) => {
        for (const stage of stages) {
          stage.value
            .then(async (ctx) => {
              const es = (
                <EtherScan
                  className="es-href"
                  address={ctx.hash}
                  text="transaction"
                  type="transaction"
                />
              )
              document.dispatchEvent(
                new CustomEvent("ErdstallOnchainEvent", {
                  detail: chain.toEvent(es, stage.name),
                  bubbles: true,
                })
              )
              this.setState({
                statusmsg: (
                  <span>
                    {stage.name} transaction {es} sent, waiting for
                    confirmation.
                  </span>
                ),
              })

              const rec = await ctx.wait()
              if (!rec.status) {
                logger.LogErrorWithField("Withdrawing EVM status")(rec.status)
                return Promise.reject("Withdraw contract execution failed.")
              }

              await stage.wait()
              document.dispatchEvent(
                new CustomEvent("ErdstallWithdraw", {
                  detail: rec,
                })
              )
            })
            .catch((obj) => {
              const reason = this.parseError(obj)
              errors.Erdstall(
                <span>{`Unable to withdraw funds: ${reason}`}</span>
              )
              this.setState({ exiting: false })
            })
        }
      })
      .catch((obj) => {
        const reason = this.parseError(obj)
        errors.Erdstall(<span>{`Unable to withdraw funds: ${reason}`}</span>)
        this.setState({ exiting: false })
      })
      .finally(() => {
        this.switchTo("Choosing")
      })
  }

  private parseError(obj: any): string {
    let reason: string
    if (obj && obj.code && obj.message) {
      reason = obj.message
    } else if (obj && obj.result) {
      reason = obj.resul
    } else if (typeof obj === "string") {
      reason = obj
    } else {
      console.log(obj)
      reason = "some error occured"
    }
    return reason
  }

  async handleApprove(
    stage: Stage<Promise<ethers.ContractTransaction>>
  ): Promise<void> {
    const ctx = await stage.value
    const es = (
      <EtherScan
        className="es-href"
        address={ctx.hash}
        text="transaction"
        type="transaction"
      />
    )
    document.dispatchEvent(
      new CustomEvent("ErdstallOnchainEvent", {
        detail: chain.toEvent(es, "Approve"),
        bubbles: true,
      })
    )
    this.setState({
      statusmsg: <span>Sent approve {es}. Waiting for confirmation...</span>,
    })
    const rec = await ctx.wait()
    if (!rec.status) {
      logger.LogErrorWithField("Approve EVM status")(rec.status)
      return Promise.reject("Approve contract execution failed.")
    }
  }

  async handleDeposit(
    stage: Stage<Promise<ethers.ContractTransaction>>
  ): Promise<void> {
    const ctx = await stage.value

    const es = (
      <EtherScan
        className="es-href"
        address={ctx.hash}
        text="transaction"
        type="transaction"
      />
    )
    document.dispatchEvent(
      new CustomEvent("ErdstallOnchainEvent", {
        detail: chain.toEvent(es, "Deposit"),
        bubbles: true,
      })
    )
    this.setState({
      statusmsg: (
        <span>
          Sent {stage.name} {es}. Waiting for confirmation...
        </span>
      ),
      depositing: true,
    })
    document.dispatchEvent(
      new CustomEvent("ErdstallSentDeposit", { bubbles: true })
    )
    document
      .getElementById("commandcenter")!
      .dispatchEvent(
        new CustomEvent("ErdstallDepositSigned", { bubbles: true })
      )
    const rec = await ctx.wait(1)
    if (!rec.status) {
      logger.LogErrorWithField("Deposit EVM status")(rec.status)
      return Promise.reject("Depositing contract execution failed.")
    }
    this.switchTo("Choosing")
    document.dispatchEvent(new CustomEvent("ErdstallDeposit"))
  }

  onDepositSubmit = async (amount: string) => {
    this.switchTo("Loading")
    this.setState({
      statusmsg: (
        <span>
          Deposit requested, sign token approve and deposit transaction to
          proceed.
        </span>
      ),
    })

    this.props
      .onDeposit(amount)
      .then(async (stages) => {
        for (const stage of stages) {
          if (stage.name === "approve") {
            await this.handleApprove(stage)
          } else if (stage.name === "deposit") {
            await this.handleDeposit(stage)
          }
        }
      })
      .catch((obj) => {
        const reason = this.parseError(obj)
        this.switchTo("TopUpActing")
        errors.Erdstall(<span>{`Deposit failed or rejected: ${reason}`}</span>)
        this.setState({
          depositing: false,
        })
      })
  }

  private buttonView(): JSX.Element {
    return (
      <Container className="align-items-center">
        <Row className="m-2">
          <Col>
            <Button
              className="es-tx-button"
              variant="outline-light"
              size="lg"
              onClick={this.switchTo.bind(this, "TXActing")}
              block
            >
              <b>Send-Transfer</b>
            </Button>
          </Col>
        </Row>
        <Row className="m-2">
          <Col>
            <Button
              className="es-topup-button"
              variant="outline-light"
              size="lg"
              onClick={this.switchTo.bind(this, "TopUpActing")}
              block
            >
              <b>Top-Up</b>
            </Button>
          </Col>
          <Col>
            {this.props.withdrawVisible &&
            this.state.exitProgress === 0 &&
            !this.state.exiting ? (
              <Button
                className="es-withdraw-button"
                disabled={this.props.withdrawDisabled}
                variant="success"
                size="lg"
                onClick={this.switchTo.bind(this, "WithdrawCommonActing")}
                block
              >
                <b>Withdraw</b>
              </Button>
            ) : (
              <Button
                className="es-leave-button"
                disabled={this.props.exited}
                variant="danger"
                size="lg"
                onClick={this.switchTo.bind(this, "LeaveActing")}
                block
              >
                <b>Leave</b>
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  private switchTo = (state: states) => {
    this.setState({ ccState: state })
  }

  render() {
    const setCurView = (s: states): JSX.Element => {
      switch (s) {
        case "TXActing":
          return (
            <TxWidget
              onRecipientChange={(arg): boolean => {
                let input: string
                if (typeof arg === "string") {
                  input = arg
                } else {
                  input = arg.target.value
                }
                const res = utils.isAddress(input)
                if (!utils.isHexString(input)) {
                  input = `0x${input}`
                }
                if (res === true) {
                  this.setState((state) => {
                    return {
                      recentRecipients: state.recentRecipients.add(input),
                    }
                  })
                }
                this.setState({
                  recipient: input,
                  isValidRecipient: res,
                })
                return res
              }}
              isValidRecipient={this.state.isValidRecipient}
              recentRecipients={this.state.recentRecipients}
              onAbort={this.onAbort}
              onSubmit={this.onTXSubmit}
            />
          )
        case "LeaveActing":
          return (
            <LeaveWidget onAbort={this.onAbort} onSubmit={this.onLeaveSubmit} />
          )
        case "TopUpActing":
          return (
            <DepositWidget
              onAbort={this.onAbort}
              onSubmit={this.onDepositSubmit}
            />
          )
        case "InfoActing":
          return (
            <InfoWidget
              onSubmit={() => {
                this.switchTo("Choosing")
              }}
            />
          )
        case "WithdrawActing":
          return (
            <WithdrawWidget
              onAbort={() => {
                this.switchTo("Choosing")
              }}
              description={text.EmergencyWithdraw}
              onWithdraw={this.withdrawFunds}
            />
          )
        case "Choosing":
          return this.buttonView()
        case "Loading":
          return <CCloader.Loader text={this.state.statusmsg} />
        case "WithdrawCommonActing":
          return (
            <WithdrawWidget
              onAbort={() => {
                this.switchTo("Choosing")
              }}
              description={text.StandardWithdraw(this.state.exitEpoch + 2n)}
              onWithdraw={this.withdrawFunds}
            />
          )
      }
    }

    const view = setCurView(this.state.ccState)

    const exitStatus = () => {
      if (!this.state.exiting) {
        return <></>
      }

      return (
        <>
          <Col></Col>
          <Col md="auto">
            <CircularProgress
              className="es-circular-background"
              variant="determinate"
              value={100}
            />
            <CircularProgress
              className="es-circular-foreground"
              variant="determinate"
              color="inherit"
              value={this.state.exitProgress}
            />
          </Col>
        </>
      )
    }

    return (
      <>
        <ErdstalLWidget
          id="commandcenter"
          className="commandcenter"
          logo={logo}
          title="COMMAND CENTER"
          leftHeader={<></>}
          rightHeader={exitStatus()}
        >
          <Container fluid>{view}</Container>
        </ErdstalLWidget>
      </>
    )
  }
}
