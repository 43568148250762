import React from "react"
import { useContext } from "react"
import {
  ShepherdTour,
  ShepherdTourContext,
  ShepherdOptionsWithType,
} from "react-shepherd"
import ESStep from "./types/erdstallstep"

const defaultStep = (): ShepherdOptionsWithType => {
  return {
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Exit",
        type: "cancel",
      },
      {
        classes: "shepherd-button-primary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",
        type: "next",
      },
    ],
    classes: "custom-class-name-1 custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
  }
}

function mkStep(): ESStep {
  return new ESStep(defaultStep())
}

export class ErdstallTutorial extends React.Component {
  waiting: boolean
  constructor(props: any) {
    super(props)
    this.waiting = true
  }

  componentDidMount() {
    document.addEventListener("ErdstallReceivedBalProof", this.onRecvdBalProof)
  }

  componentWillUnmount() {
    document.removeEventListener(
      "ErdstallReceivedBalProof",
      this.onRecvdBalProof
    )
  }

  onRecvdBalProof = () => {
    console.log("Set tutorial to NOT wait for BP")
    this.waiting = false
    console.log(`waiting: ${this.waiting}`)
  }

  render() {
    const steps = [
      mkStep()
        .Id("intro")
        .Delay(100)
        .Title("Welcome to Erdstall")
        .Text(
          [
            "This little tour will guide you through the demo and explain some things here and there.",
            "You can press ESC at any time or the Exit button to leave the tour.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Id("Status")
        .AttachTo({ element: ".esNavBar", on: "bottom" })
        .Title("Status")
        .Text("The NavBar shows your status and the status of the operator.")
        .Build(),
      mkStep()
        .Id("Operator-Status")
        .AttachTo({ element: ".operator-status", on: "bottom" })
        .Title("Operator-Status")
        .CanClickTarget(false)
        .Text(
          "Clicking on the Operator-Status button will reveal on-chain addresses related to the operator and Erdstall."
        )
        .Build(),
      mkStep()
        .Id("IntroCommandcenter")
        .AttachTo({
          element: ".commandcenter",
          on: "bottom",
        })
        .Title("Command center")
        .Text(
          [
            "The command center is your interactive panel, providing all necessary functions to interact with Erdstall.",
            "Functionality of the buttons will be explained in a minute, lets first introduce you to the rest.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Id("IntroCommandcenterOnChainBal")
        .AttachTo({
          element: ".es-onchainbal",
          on: "bottom",
        })
        .Arrow(false)
        .Title("Balances")
        .Text(
          [
            "This shows your accounts onchain balance.",
            "We use an ERC20 token called Perun (PRN), which you can use to interact with Erdstall.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Id("IntroCommandcenterOffChainBal")
        .AttachTo({
          element: ".es-offchainbal",
          on: "bottom",
        })
        .Arrow(false)
        .Title("Balances")
        .Text("This shows your accounts Erdstall balance (PRN).")
        .Build(),
      mkStep()
        .Id("IntroCommandcenterOffChainBal")
        .AttachTo({
          element: ".es-withdrawableBals",
          on: "bottom",
        })
        .Arrow(false)
        .Title("Balances")
        .Text(
          "This shows the amount of funds, which are withdrawable by you with an on-chain call, more on this later."
        )
        .Build(),
      mkStep()
        .Id("IntroTXHistory")
        .AttachTo({
          element: ".txhistory",
          on: "top",
        })
        .Title("History")
        .Text(
          [
            "The History will log all of your outgoing and incoming TXs within Erdstall, as well as all on-chain activity related to you.",
            "It will provide metainformation like the status of the transaction, amount transferred and recipient/sender.",
            "If this is your first time joining the system you will receive a few TXs from our Bot to help you get started.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Id("IntroEpochmeter")
        .AttachTo({
          element: ".phasemeter",
          on: "top",
        })
        .CanClickTarget(false)
        .Title("Epochmeter")
        .Text("The Epochmeter is indicating the current state of Erdstall.")
        .Build(),
      mkStep()
        .Id("IntroEpochmeterBoxes")
        .AttachTo({
          element: ".phaseboxVal",
          on: "top",
        })
        .CanClickTarget(false)
        .Title("Epochs")
        .Text(["Erdstall runs in epochs, displayed here."].join(" "))
        .Build(),
      mkStep()
        .Id("IntroEpochmeterMeter")
        .AttachTo({
          element: ".es-phasemeter",
          on: "top",
        })
        .CanClickTarget(false)
        .Title("Epochmeter")
        .Text(
          [
            "Each new block on the blockchain leads to the epochmeter filling up.",
            "When the meter is filled an epochshift will occur, which can be seen by observing a shift of the epochnumber.",
            "Now, let's get to the real tea 🍵.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Delay(200)
        .Id("IntroOnboardingPending")
        .AttachTo({
          element: ".es-maincolumn",
          on: "left",
        })
        .Buttons([])
        .Arrow(false)
        .CancelIcon({ enabled: false })
        .Title("Onboarding")
        .Text(
          [
            "We now have to wait for the pending bot transactions to be finalized.",
            "In the meantime you might check out the extra information about epochs to better understand their meaning.",
            "We will guide you further as soon as the system is ready.",
          ].join(" ")
        )
        .AdvanceOn({
          selector: ".es-maincolumn",
          event: "ErdstallReceivedBalProof",
        })
        .ShowOn(() => {
          console.log(`waiting for pendingTXs?: ${this.waiting}`)
          return this.waiting
        })
        .Build(),
      mkStep()
        .BeforeShowPromise(() => {
          return new Promise((resolve) => {
            document.dispatchEvent(new CustomEvent("ErdstallReset"))
            setTimeout(() => {
              resolve(undefined)
            }, 100)
          })
        })
        .Id("IntroTX")
        .AttachTo({
          element: ".es-tx-button",
          on: "bottom",
        })
        .Title("Transacting")
        .Text(
          [
            "We are now ready to send our first transaction using Erdstall.",
            'Continue by clicking on "Send-Transfer"',
          ].join(" ")
        )
        .Buttons([])
        .AdvanceOn({
          selector: ".es-tx-button",
          event: "click",
        })
        .Build(),
      mkStep()
        .Delay(100)
        .CancelIcon({ enabled: false })
        .Id("IntroTXTransacting")
        .AttachTo({
          element: ".commandcenter",
          on: "bottom",
        })
        .Arrow(false)
        .Title("Transacting")
        .Text(
          [
            "You might use yourself as a recipient, if you do not have another address to use.",
            "Another option is sendin the bot some funds, in this case it will echo your amount back in multiple microtransactions.",
            "The dropdown menu has your address available by default.",
          ].join(" ")
        )
        .Buttons([])
        .AdvanceOn({
          selector: ".es-primary-button",
          event: "click",
        })
        .Build(),
      mkStep()
        .CancelIcon({ enabled: false })
        .Buttons([])
        .AttachTo({
          element: ".commandcenter",
          on: "bottom",
        })
        .Arrow(false)
        .Id("PromptSignMMTX")
        .Title("Transacting")
        .Text("Sign the offchain TX, by using the MetaMask popup.")
        .AdvanceOn({
          selector: ".es-maincolumn",
          event: "ErdstallOffchainTXSigned",
        })
        .Build(),
      mkStep()
        .Id("IntroTXHistoryUse")
        .AttachTo({
          element: ".txhistory",
          on: "bottom",
        })
        .Title("TX-History")
        .Text(
          [
            "Sending an Erdstall TX will populate the TX history.",
            "Each entry shows the amount transferred, direction, status as well as the recipient/sender (depending on the direction).",
            "In case of an error, you can hover over the little icon between direction and address and get contextual information.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Id("IntroRoundup")
        .Title("Final words")
        .Text("You might now send and receive TXs as you please.")
        .Build(),
      mkStep()
        .Id("IntroFinalize01")
        .Title("Final words")
        .AttachTo({
          element: ".es-leave-button",
          on: "bottom",
        })
        .CanClickTarget(false)
        .Text(
          [
            'When you are done, you are able to leave Erdstall and withdraw your funds by utilizing the "Leave" button.',
            "Leaving the sytem will be possible as soon as you receive a proof of your current Erdstall balance by the operator.",
            "When doing so, you will see your Withdrawable (PRN) reflect the amount you are leaving the system with.",
          ].join(" ")
        )
        .Build(),
      mkStep()
        .Id("IntroFinalize02")
        .Title("Final words")
        .AttachTo({
          element: ".es-leave-button",
          on: "bottom",
        })
        .CanClickTarget(false)
        .Buttons([
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
          },
          {
            classes: "shepherd-button-secondary",
            text: "Finish",
            type: "cancel",
          },
        ])
        .Text(
          [
            "Note that you need some testnet ether to exit the system and claim your precious PRN.",
            "Big thank you for trying out this demo, have fun and a wonderful day 🎉.",
          ].join(" ")
        )
        .Build(),
    ]
    return (
      <ShepherdTour
        steps={steps}
        tourOptions={{
          defaultStepOptions: {
            cancelIcon: {
              enabled: true,
            },
          },
          useModalOverlay: true,
        }}
      >
        <Tutorial />
      </ShepherdTour>
    )
  }
}

function Tutorial() {
  const tour = useContext(ShepherdTourContext)
  React.useEffect(() => {
    document.addEventListener("ErdstallStartTutorial", tour!.start)
    return () => {
      document.removeEventListener("ErdstallStartTutorial", tour!.start)
    }
  })
  return <></>
}
