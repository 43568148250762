// SPDX-License-Identifier: Apache-2.0

import React from "react"
import ErdstallWidget from "./erdstallwidget"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import ProgressBar from "react-bootstrap/ProgressBar"
import { FaTachometerAlt } from "react-icons/fa"
import { Epoch } from "./types/epoch"

const logo = <FaTachometerAlt />

interface phasemeterProps {
  epoch: Epoch
  progress: number
  duration: number
}

export function PhaseMeter(props: phasemeterProps) {
  const t = (
    <>
      The <b>phase meter</b> gives an overview of the current epoch numbers and
      the progress for each Phase. Each epoch spans multiple blocks. Right now,
      each epoch lasts <b>{props.duration}</b> blocks.
    </>
  )

  return (
    <ErdstallWidget
      className="phasemeter"
      logo={logo}
      title="EPOCH METER"
      info={t}
    >
      <Container fluid>
        <OverlayTrigger
          placement="top-start"
          overlay={
            <Tooltip id="pmm">Shows the progress into each epoch.</Tooltip>
          }
        >
          <ProgressBar className="es-phasemeter" now={props.progress} />
        </OverlayTrigger>
        <Row className="es-phasebox-row">
          <div className="es-phaselabel">
            <div className="phaseboxVal">{props.epoch.toString()}</div>
          </div>
        </Row>
      </Container>
    </ErdstallWidget>
  )
}
