// SPDX-License-Identifier: Apache-2.0

import React, { Component } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { ErdstallOnboard } from "./text"
import "./erdstallmodal.css"

interface props {
  onSubmit: () => Promise<void>
  onFatal: () => void
}

interface modalProps {
  show: boolean
  onHide: () => void
}

interface state {
  modalVisible: boolean
  amount: number
}

export class ErdstallModal extends Component<props, state> {
  constructor(props: props) {
    super(props)
    this.state = {
      modalVisible: false,
      amount: 0,
    }
  }

  erdstallModal = (props: modalProps) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Entering Erdstall
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{ErdstallOnboard}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              this.onToggleModal()
            }}
          >
            Keep me out 😖
          </Button>
          <Button
            variant="outline-primary"
            onClick={async () => {
              this.props.onSubmit().catch((reason) => {
                alert(
                  `Ooops, ${reason}. Better refresh the page and try again.`
                )
                console.error(reason)
                this.onToggleModal()
                this.props.onFatal()
              })
            }}
          >
            Let me in! 😍
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  onToggleModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible })
  }

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.valueAsNumber
    this.setState({ amount: isNaN(val) ? 0 : val })
  }

  render() {
    return (
      <>
        <this.erdstallModal
          show={this.state.modalVisible}
          onHide={this.onToggleModal}
        />
        <Button
          variant="outline-primary"
          size="lg"
          onClick={this.onToggleModal}
        >
          Onboard
        </Button>
      </>
    )
  }
}
