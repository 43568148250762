// SPDX-License-Identifier: Apache-2.0

import React from "react"
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap"
import { utils } from "ethers"
import { PrimaryButton, ReturnButton } from "./button"
import * as logger from "./types/logger"

interface prop {
  onRecipientChange: (
    event: React.ChangeEvent<HTMLInputElement> | string
  ) => boolean
  isValidRecipient: boolean
  recentRecipients: Set<string>
  onAbort: () => void
  onSubmit: (amount: string) => void
}

export function TxWidget(props: prop) {
  const [input, setInput] = React.useState("")
  const [amount, setAmount] = React.useState("")
  const [isValidTXAmount, setValidTXAmount] = React.useState(true)
  const dropdownElems: JSX.Element[] = []
  const setToElements = (addr: string) => {
    return dropdownElems.push(
      <Dropdown.Item
        as="button"
        key={addr}
        id={addr}
        onClick={(ev) => {
          const addr = ev.currentTarget.id as string
          setInput(addr)
          props.onRecipientChange(addr)
        }}
      >
        {addr}
      </Dropdown.Item>
    )
  }

  const validateEtherInput = (val: string): boolean => {
    try {
      utils.parseEther(val)
      return true
    } catch (reason) {
      logger.LogErrorWithField("DepositWidget")(reason)
      return false
    }
  }

  props.recentRecipients.forEach(setToElements)
  return (
    <>
      <Container className="justify-contents-center" fluid>
        <Row>
          <p>
            You are about to create an off-chain transaction. Submitting the
            transaction will ask you to sign the transaction data.
          </p>
        </Row>
        <Row className="p-1">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Enter TX-Amount (PRN):</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="string"
                name="TXAmount"
                isInvalid={amount === "" ? undefined : !isValidTXAmount}
                isValid={amount === "" ? undefined : isValidTXAmount}
                onChange={(ev) => {
                  const amount = ev.target.value
                  setAmount(amount)
                  setValidTXAmount(validateEtherInput(amount))
                }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="p-1">
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Enter Recipient:</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                isInvalid={input === "" ? undefined : !props.isValidRecipient}
                isValid={input === "" ? undefined : props.isValidRecipient}
                type="string"
                name="Recipient"
                value={input}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  setInput(ev.target.value)
                  props.onRecipientChange(ev)
                }}
              />
              <Form.Control.Feedback tooltip={true} type="invalid">
                Not a valid ethereum address.
              </Form.Control.Feedback>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  split
                  variant="secondary"
                  id="recentRecipients"
                />
                <Dropdown.Menu align="right">{dropdownElems}</Dropdown.Menu>
              </Dropdown>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <Container className="justify-content-center mt-2" fluid>
        <Row className="justify-content-end" noGutters>
          <Col className="m-1" md="auto">
            <ReturnButton onClick={props.onAbort}>Abort</ReturnButton>
          </Col>
          <Col className="m-1" md="auto">
            <PrimaryButton
              disabled={!props.isValidRecipient}
              onClick={() => {
                props.onSubmit(amount)
                setAmount("0")
              }}
            >
              Submit
            </PrimaryButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}
