// SPDX-License-Identifier: Apache-2.0

import React from "react"
import { InputGroup } from "react-bootstrap"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

interface prop {
  label: string
  symbol?: any
  tooltip?: string
  className?: string
  id?: string
  status?: string | undefined | number
  onClick?: (ev: React.MouseEvent) => void
}

export function StatusWidget(props: prop) {
  const [bg, setBg] = React.useState("es-status-unselected")
  const handleStatus = () => {
    if (props.status !== undefined) {
      return (
        <>
          <InputGroup.Text>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={props.label}>
                  {props.tooltip === undefined ? "" : props.tooltip}
                </Tooltip>
              }
            >
              <span className="statuswidgetIcon">{props.symbol}</span>
            </OverlayTrigger>
          </InputGroup.Text>
          <InputGroup.Append>
            <InputGroup.Text>
              <span className="small">{props.status}</span>
            </InputGroup.Text>
          </InputGroup.Append>
        </>
      )
    } else {
      return (
        <>
          <InputGroup.Append>
            <InputGroup.Text>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={props.label}>
                    {props.tooltip === undefined ? "" : props.tooltip}
                  </Tooltip>
                }
              >
                <span className="statuswidgetIcon">{props.symbol}</span>
              </OverlayTrigger>
            </InputGroup.Text>
          </InputGroup.Append>
        </>
      )
    }
  }

  return (
    <>
      <InputGroup className={`${props.className}`}>
        <InputGroup.Prepend>
          <InputGroup.Text
            className={`${props.id} ${bg}`}
            style={{ cursor: "pointer" }}
            onClick={props.onClick}
            onMouseEnter={() => {
              setBg("es-status-selected")
            }}
            onMouseLeave={() => {
              setBg("es-status-unselected")
            }}
          >
            <span className="small statuswidgetLabel font-weight-bold">
              {props.label}
            </span>
          </InputGroup.Text>
        </InputGroup.Prepend>
        {handleStatus()}
      </InputGroup>
    </>
  )
}
