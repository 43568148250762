// SPDX-License-Identifier: Apache-2.0

import React, { Component } from "react"
import InitWeb3 from "./web3"
import { providers, BigNumber } from "ethers"
import { ClientView } from "./clientview"
import { Config } from "./client/loader"
import { Erdstall } from "./abi/Erdstall"
import { PerunToken } from "./abi/PerunToken"
import { Erdstall__factory } from "./abi/factories/Erdstall__factory"
import { PerunToken__factory } from "./abi/factories/PerunToken__factory"
import Loader from "react-loader-spinner"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { ErdstallTutorial } from "./webtour"
import { ErdstallOnboardLoading } from "./text"
import { LandingPage } from "./LandingPage"
import { Tracker } from "./types/tracker"
import * as logger from "./types/logger"
import { Session } from "@polycrypt/erdstall"
import { Address } from "@polycrypt/erdstall/ledger"
import { Enclave, EnclaveWSProvider } from "@polycrypt/erdstall/enclave"
import { ClientConfig } from "@polycrypt/erdstall/api/responses"
import { NetworkID } from "./networks"
import { FindRegisteredTokenWithSymbol } from "./client/erdstall"
import "./App.css"
import "./erdstallwidgets.css"

// client configuration.
const config = require("./config.json") as Config

// abi has to be imported in the JS way, since we don't want any typings for
// the resulting JSON contract. Else contract creation fails. We get typesafety
// by utilizing `Typechain` and generated bindings.
//const ErdstallJSON = require("./abi/Erdstall.json")

type AppState = "LandingPage" | "Onboarding" | "Commanding"

interface State {
  AppState: AppState
}

class App extends Component<{}, State> {
  provider?: providers.Web3Provider
  acc?: string
  teeaddr?: string
  contractAddr?: string
  perunAddr?: string
  powdepth: number
  networkid?: string
  contract?: Erdstall
  token?: PerunToken
  esdk?: Session
  tracker?: Tracker
  initialzed: boolean
  ws?: WebSocket

  constructor(props: any) {
    super(props)
    this.initialzed = false
    this.powdepth = 0
    this.state = {
      AppState: "LandingPage",
    }
  }

  componentWillMount() {
    document.addEventListener(
      "ErdstallAbort",
      this.switchTo.bind(this, "LandingPage")
    )
  }

  componentDidMount() {
    const ep = new EnclaveWSProvider(
      new URL(
        `${config.OperatorProtocol}://${config.OperatorAddr}:${config.OperatorPort}/ws`
      )
    )
    const ec = new Enclave(ep)
    ec.once("config", (cfg: ClientConfig) => {
      const networkID = cfg.networkID
      const contract = cfg.contract
      const powDepth = cfg.powDepth

      logger.LogInfoWithField("ClientConfig")(cfg)
      this.networkid = networkID
      this.contractAddr = contract.toString()
      this.powdepth = powDepth
      this.initialzed = true
      ec.disconnect()
    })
    ec.connect()
  }

  componentWillUnmount() {
    document.removeEventListener(
      "ErdstallAbort",
      this.switchTo.bind(this, "LandingPage")
    )
  }

  private onboarding() {
    return (
      <div className="d-flex h-100 justify-content-center">
        <Col className="esbox align-self-center" md="auto">
          <Row className="ml-2 mr-2 justify-content-center">
            <Loader type="Oval" color="#00bfff" height={200} width={200} />
          </Row>
          <Row className="mt-2 ml-2 mr-2 justify-content-center">
            {ErdstallOnboardLoading}
          </Row>
        </Col>
      </div>
    )
  }

  render() {
    const title = "🍵 ERDSTALL"

    const setView = () => {
      switch (this.state.AppState) {
        case "LandingPage":
          return (
            <>
              <Navbar className="esNavBar plane" variant="dark">
                <Navbar.Brand href="https://erdstall.dev">{title}</Navbar.Brand>
              </Navbar>
              <Container className="" fluid>
                <LandingPage
                  onSubmit={async () => {
                    this.switchTo("Onboarding")
                    await this.onSubmit()
                      .then(() => {
                        this.switchTo("Commanding")
                      })
                      .catch((reason) => {
                        logger.LogErrorWithField("LandingPage-OnSubmit")(reason)
                        alert(reason)
                        this.switchTo("LandingPage")
                      })
                  }}
                  onFatal={() => {
                    this.switchTo("LandingPage")
                  }}
                />
              </Container>
            </>
          )
        case "Onboarding":
          return this.onboarding()
        case "Commanding":
          return (
            <ClientView
              key="ErdstallClient"
              provider={this.provider!}
              acc={this.acc!}
              teeaddr={this.teeaddr!}
              contract={this.contract!}
              token={this.token!}
              tracker={this.tracker!}
              esdk={this.esdk!}
            />
          )
      }
    }

    return (
      <>
        {setView()}
        <ErdstallTutorial />
      </>
    )
  }

  // onSubmit defines the resulting action, when a user decides to enter the
  // erdstall system by giving this dApp access to a web3 instance connected
  // to his account.
  private onSubmit = async () => {
    if (!this.initialzed) {
      throw Error("Did not receive client configuration from operator")
    }

    const web3Provider = await InitWeb3()
    const ethereum = web3Provider.provider! as any

    if (!ethereum.isMetaMask) {
      throw Error("Please install MetaMask to enjoy the Erdstall experience.")
    }

    if (!ethereum.isConnected()) {
      throw Error(
        "Provider not properly connected to network, check your (blockchain) network settings"
      )
    }

    const netid = Number(this.networkid)
    if (Number(ethereum.chainId) !== netid) {
      if (!NetworkID.has(netid)) {
        throw Error("Currently selected network not supported.")
      }

      const network = NetworkID.get(Number(this.networkid))
      throw Error(
        `Not connected to correct network, please connect to ${network}`
      )
    }

    let accounts: string[] = []
    try {
      await web3Provider.provider.request!({
        method: "eth_requestAccounts",
      }).then((accs: string[]) => {
        logger.LogWithField("AccountRequest")(accs)
        accounts = accs
        if (accounts.length <= 0) {
          return Promise.reject("No accounts specified")
        }
      })
    } catch {
      throw Error(
        "Rejected account selection, please connect an account with sufficient funds to join the system."
      )
    }

    const erdstall = Erdstall__factory.connect(
      this.contractAddr!,
      web3Provider.getSigner()
    )

    const tokenSym = "PRN"
    const tokenAddr = await FindRegisteredTokenWithSymbol(erdstall, 0, tokenSym)
    if (!tokenAddr) {
      throw Error(
        `Unable to resolve ${tokenSym} contract address for demo purposes.`
      )
    }

    const token = PerunToken__factory.connect(
      tokenAddr,
      web3Provider.getSigner()
    )

    // When an account is available querying the account balance should be
    // ALWAYS possible. The final step to properly ensure, that the current
    // network is properly initialized and not poisoned by some outdated cached
    // or just plain wrong information is querying the current blockchain
    // state. If this fails, the user might be using a private network, which
    // was restarted prior to current use of the client.
    try {
      await web3Provider.getBalance(accounts[0])
    } catch {
      throw Error(
        "Something is wrong with the network, reselecting the current network in your provider settings might fix this."
      )
    }
    let pd: BigNumber
    let bb: BigNumber
    let tee: string
    try {
      pd = await erdstall.epochDuration()
      logger.LogWithField("EpochDuration")(pd)
      bb = await erdstall.bigBang()
      logger.LogWithField("BigBang")(bb)
      tee = await erdstall.tee()
      logger.LogWithField("TEE-Address")(tee)
    } catch {
      throw Error(
        "Unable to retrieve contract data. Are you signed into the correct network?"
      )
    }

    const tracker = new Tracker(
      pd.toNumber(),
      BigInt(bb.toString()),
      this.powdepth,
      config.BufferDepth
    )

    this.contract = erdstall
    this.token = token
    this.provider = web3Provider
    this.acc = accounts[0]
    this.teeaddr = tee
    this.tracker = tracker
    this.esdk = new Session(
      Address.fromString(this.acc),
      web3Provider.getSigner(),
      new URL(
        [
          config.OperatorProtocol,
          "://",
          config.OperatorAddr,
          ":",
          config.OperatorPort,
          "/ws",
        ].join("")
      )
    )
  }

  private switchTo(state: AppState): void {
    this.setState({ AppState: state })
  }
}

export default App
