// SPDX-License-Identifier: Apache-2.0

import "bootstrap/dist/css/bootstrap.min.css"
import "shepherd.js/dist/css/shepherd.css"
import "./index.css"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./fonts/Roboto/Roboto-Bold.ttf"
import "./fonts/Roboto/Roboto-Regular.ttf"

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
