// SPDX-License-Identifier: Apache-2.0

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ReactPlayer from "react-player"
import { ErdstallModal } from "./modal"
import { ErdstallIntro } from "./text"

interface prop {
  onSubmit: () => Promise<void>
  onFatal: () => void
}

export function LandingPage(props: prop) {
  return (
    <>
      <Row className="m-2">
        <Container className="Intro-Text">
          <p>{ErdstallIntro}</p>
        </Container>
      </Row>
      <Row className="m-2 justify-content-center">
        <Col md="auto">
          <Container className="Intro-Video plane pl-0 pr-0">
            <ReactPlayer url="https://youtu.be/T8g2JFYcSug?t=3940" />
          </Container>
        </Col>
      </Row>
      <Row className="m-2 justify-content-center">
        <ErdstallModal onSubmit={props.onSubmit} onFatal={props.onFatal} />
      </Row>
    </>
  )
}
