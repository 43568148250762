// SPDX-License-Identifier: Apache-2.0

// logger exports some simple functions to ease the task of logging.

export const Log = console.log

export const LogWithField = (tag: string): ((val: any) => void) => {
  return logWithFieldAndType(tag, console.log)
}

export const LogInfoWithField = (tag: string): ((val: any) => void) => {
  return logWithFieldAndType(tag, console.info)
}

export const LogWarningWithField = (tag: string): ((val: any) => void) => {
  return logWithFieldAndType(tag, console.warn)
}

export const LogErrorWithField = (tag: string): ((val: any) => void) => {
  return logWithFieldAndType(tag, console.error)
}

const logWithFieldAndType = (
  tag: string,
  f: (msg: any, ...ps: any[]) => void
) => {
  return (val: any): void => {
    f(tag, ": ", val)
  }
}
