// SPDX-License-Identifier: Apache-2.0

// Client describes the Erdstall client, its corresponding state and interfacing
// functions.
import { ethers, utils, BigNumber, providers } from "ethers"
import { Tracker } from "../types/tracker"
import { Watcher } from "../types/watcher"
import { Epoch } from "../types/epoch"
import { Erdstall } from "../abi/Erdstall"
import { ERC20__factory } from "../abi/factories/ERC20__factory"
import { ERC20 } from "../abi/ERC20"

import { RingBuffer } from "../types/ringbuffer"
import { Session } from "@polycrypt/erdstall"
import { Account } from "@polycrypt/erdstall/ledger"
import { Address } from "@polycrypt/erdstall/ledger"
import { TxReceipt, ClientConfig } from "@polycrypt/erdstall/api/responses"
import { BalanceProof, Balance } from "@polycrypt/erdstall/api/responses"
import { Stages } from "@polycrypt/erdstall/utils"
import { Transfer } from "@polycrypt/erdstall/api/transactions"
import { Assets } from "@polycrypt/erdstall/ledger/assets"
import { Amount } from "@polycrypt/erdstall/ledger/assets"
import { Signature } from "@polycrypt/erdstall/api/signature"
import * as logger from "../types/logger"
import * as transaction from "../types/transaction"
import * as errors from "../types/error"

// ErdstallClient is a client who is connected with an Ethereum `account` and
// the ErdstallOperator.
export class ErdstallClient {
  private readonly provider: ethers.providers.Web3Provider
  private readonly ethereum: any // MetaMask-Provider
  private erdstall: Erdstall
  private erc20: ERC20
  private balanceProofs: RingBuffer<BalanceProof>
  private intervalHandle?: number
  private tracker: Tracker
  private offchainBals: Assets
  private withdrawableBals: Assets
  private currentEpoch: Epoch
  private dpEpoch: Epoch
  private watcher: Watcher
  private activeBlocknum: bigint // block which is CURRENTLY being mined.
  private exitBP?: BalanceProof
  private exitRequested: boolean
  private esdk: Session
  private token: string
  public Address: Address
  public Account: Account

  constructor(
    erdstall: Erdstall,
    cl: Session,
    acc: string,
    token: string,
    provider: providers.Web3Provider,
    tracker: Tracker
  ) {
    this.erdstall = erdstall
    this.erc20 = ERC20__factory.connect(token, provider)
    this.token = token
    this.esdk = cl
    this.provider = provider
    this.ethereum = provider.provider as any

    this.tracker = tracker
    this.Address = new Address(utils.arrayify(acc))
    this.Account = new Account(0n, new Assets(), new Assets())

    this.balanceProofs = new RingBuffer(5)

    this.offchainBals = new Assets()
    this.withdrawableBals = new Assets()

    this.activeBlocknum = 0n
    this.dpEpoch = 0n
    this.currentEpoch = 0n

    this.exitRequested = false

    this.watcher = new Watcher(provider)
    this.watcher.WatchBlocks()
    document.addEventListener("ErdstallBlock", this.onBlock)
    document.addEventListener("ErdstallWithdraw", this.onWithdrawEvent)
    document.addEventListener("ErdstallDeposit", this.onDepositEvent)
    document.addEventListener("ErdstallRetryTX", this.onRetryTX)

    this.ethereum.on("disconnect", this.onRPCDisconnect)
    this.ethereum.on("chainChanged", this.onChainChanged)
    this.ethereum.on("accountsChanged", this.onAccountsChanged)

    this.esdk.on("config", (cfg: ClientConfig) => {
      console.log("Operator send clientconfig")
      console.log(cfg)
    })
    this.esdk.on("exitproof", (ep: BalanceProof) => {
      console.log("Received ExitProof from operator")
      this.onExitProof(ep)
    })
    this.esdk.on("proof", (proof: BalanceProof) => {
      console.log("Received Proof from operator")
      this.onBalanceProof(proof)
    })
    this.esdk.on("receipt", (rec: TxReceipt) => {
      console.log("Received tx receipt from operator")
      this.onTxReceipt(rec)
      document.dispatchEvent(new CustomEvent("ErdstallBalanceUpdate"))
    })
    this.esdk.on("error", (e: any) => {
      console.info("Received error from operator")
      console.error(e)
    })
    this.esdk.on("open", () => {
      this.esdk.subscribe()
    })

    const onboarding = () => {
      this.esdk.onboard()
      document.removeEventListener("ErdstallBlock", onboarding)
    }
    document.addEventListener("ErdstallBlock", onboarding)

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("ErdstallConnected"))
    }, 200)

    this.esdk.initialize().then(() => {
      this.esdk.subscribeSelf()
    })
  }

  // Cleans up every subscription.
  CleanUp = () => {
    this.ethereum.removeAllListeners()
    this.provider.removeAllListeners()
    document.removeEventListener("ErdstallBlock", this.onBlock)
    document.removeEventListener("ErdstallWithdraw", this.onWithdrawEvent)
    document.removeEventListener("ErdstallDeposit", this.onDepositEvent)
    document.removeEventListener("ErdstallRetryTX", this.onRetryTX)
    window.clearInterval(this.intervalHandle)
  }

  // OffChainBalance returns the current erdstall balance according to the latest
  // balance.
  OffChainBalance = (): string => {
    if (!this.offchainBals.hasAsset(this.token)) {
      return "unknown"
    }
    const offchain = this.offchainBals.values.get(this.token) as Amount
    return utils.formatUnits(ethers.BigNumber.from(offchain.value), "ether")
  }

  WithdrawableBalance = (): string => {
    if (!this.withdrawableBals.hasAsset(this.token)) {
      return "0"
    }
    const amount = this.withdrawableBals.values.get(this.token)! as Amount
    return utils.formatUnits(ethers.BigNumber.from(amount.value), "ether")
  }

  // OnChainBalance returns the onchain balance of the clients account.
  OnChainBalance = (): Promise<ethers.BigNumber> => {
    return this.erc20.balanceOf(this.Address.toString())
  }

  // OnWithdraw leaves the system by withdrawing with the latest sealed exit epoch.
  // Throws error when trying to withdraw in invalid state.
  OnWithdraw = (): Promise<Stages<Promise<ethers.ContractTransaction>>> => {
    return this.esdk.withdraw(this.exitBP!)
  }

  private subOffchainBalance = (val: bigint) => {
    this.actOnOffchainBalance(val, (l, r) => {
      return l - r
    })
  }

  private addOffchainBalance = (val: bigint) => {
    this.actOnOffchainBalance(val, (l, r) => {
      return l + r
    })
  }

  private setOffchainBals = (val: bigint) => {
    this.actOnOffchainBalance(val, (_, rhs) => {
      return rhs
    })
  }

  private actOnOffchainBalance = (
    val: bigint,
    op: (offChainBals: bigint, rhs: bigint) => bigint
  ) => {
    let amount = this.offchainBals.values.get(this.token) as Amount | undefined
    if (!amount) {
      amount = new Amount(0n)
    }
    this.offchainBals.values.set(this.token, new Amount(op(amount.value, val)))
  }

  // OnDeposit tries to deposit given amount to the Erdstall contract.
  OnDeposit = async (
    amount: string
  ): Promise<Stages<Promise<ethers.ContractTransaction>>> => {
    const input = amount === "" ? "0" : amount
    const val = utils.parseEther(input)
    this.dpEpoch = this.tracker.Epoch(this.activeBlocknum)
    console.log(`Depositing in epoch ${this.dpEpoch}`)
    const dep = new Assets()
    dep.addAsset(this.token, new Amount(val.toBigInt()))
    return this.esdk.deposit(dep)
  }

  OnExit = async (): Promise<BalanceProof> => {
    if (this.exitRequested) {
      return Promise.reject(new Error("Exit already requested."))
    }

    this.warnIfNoEtherOnAccount()
    this.exitRequested = true
    return this.esdk.exit().then((bp) => {
      this.queueWithdrawing()
      return bp
    })
  }

  private async warnIfNoEtherOnAccount(): Promise<void> {
    this.provider.getBalance(this.Address.toString()).then((bal) => {
      if (!bal.isZero()) {
        return
      }
      errors.Erdstall(
        <span>
          It looks like you do not own any ether on this account. You can still
          leave the system and sign the exit-transaction, but it would be
          preferable to first load up some testnet ether otherwise one would not
          be able to sign the upcoming on-chain withdraw transaction.
        </span>
      )
    })
  }

  // queueWithdrawing will wait for the current and next Epoch to end, plus
  // some more "buffer" blocks in case we are out of sync with the Enclave.
  private queueWithdrawing = () => {
    const maxBlocksToWait = 2 * this.tracker.EpochDuration
    const numBufferBlocks = 3
    const progressCurrentEpoch = this.tracker.ProgressWithinEpoch(
      this.activeBlocknum
    )
    const max = maxBlocksToWait + numBufferBlocks - progressCurrentEpoch
    this.doOnBlocks(
      max,
      this.updateExitProgress.bind(this.updateExitProgress, max),
      () => {
        document.dispatchEvent(new CustomEvent("ErdstallExecuteWithdraw"))
        this.exitRequested = false
      }
    )
  }

  private updateExitProgress = (n: number, remaining: number) => {
    document.dispatchEvent(
      new CustomEvent("ErdstallUpdateExitProgress", {
        detail: remaining !== 0 ? 1 - remaining / (n + 1) : 1,
      })
    )
  }

  // doOnBlocks executes the given `cb` after `n` blocks elapsed and `onBlock`
  // on each block.
  private doOnBlocks = (
    n: number,
    onBlock: (progress: number) => void,
    end: () => void
  ) => {
    document.addEventListener(
      "ErdstallBlock",
      this.doonblocks.bind(this.doonblocks, n, onBlock, end),
      { once: true }
    )
  }

  // doonblocks is an auxiliary function to enable the call to `removeEventListener`.
  private doonblocks = (
    n: number,
    onBlock: (progress: number) => void,
    cb: () => void
  ) => {
    if (n > 0) {
      console.log(`decrementing blockcount ${n}`)
      onBlock(n)
      document.addEventListener(
        "ErdstallBlock",
        this.doonblocks.bind(this.doonblocks, n - 1, onBlock, cb),
        { once: true }
      )
    } else {
      cb()
    }
  }

  // OnSendTX creates a transaction from the current registered account to
  // specified recipient with the given amount.
  OnSendTX = async (recipient: string, amount: string): Promise<TxReceipt> => {
    const wei = utils.parseEther(amount)
    const val = new Assets()
    val.addAsset(this.token, new Amount(wei.toBigInt()))
    return this.esdk.transferTo(val, Address.fromString(recipient))
  }

  private onRetryTX = (ev: CustomEventInit) => {
    const tx: transaction.Transfer = ev.detail
    this.OnSendTX(
      tx.recipient,
      utils.formatEther(BigNumber.from(tx.amount))
    ).catch((reason) => {
      errors.Erdstall(<span>{reason}</span>)
    })
  }

  private onRPCDisconnect = () => {
    alert("RPC Provider unreachable.")
    document.dispatchEvent(new CustomEvent("ErdstallAbort"))
  }

  private onChainChanged = () => {
    alert(
      "Change of networks detected, please make sure to stay on the same chain."
    )
    document.dispatchEvent(new CustomEvent("ErdstallAbort"))
  }

  private onAccountsChanged = async (accounts: string[]) => {
    if (accounts.length === 0) {
      // Wallet is locked, which is fine, since the user can unlock his account
      // on demand when being forced to sign TXs. On unlock the
      // `accountsChanged` event is emitted, in which we check if the current
      // account changed or not.
      if (!(await this.ethereum._metamask.isUnlocked())) {
        return alert(
          "Locking your account is okay, but to use any of our provided features, you would need to manually unlock your account everytime a signature or on-chain action is required."
        )
      }

      alert(
        "You disconnected all of your accounts. You need at least one account connected to use this system. Back to the landing page..."
      )
      document.dispatchEvent(new CustomEvent("ErdstallAbort"))
      return
    }

    if (accounts[0] !== this.Address.toString()) {
      alert("Account switch detected, reloading client to handle new account.")
      this.Address = Address.fromString(accounts[0])
      this.reset()
      return
    }
  }

  private onWithdrawEvent = () => {
    this.reset()
  }

  private onDepositEvent = () => {}

  private onBlock = (ev: CustomEventInit) => {
    this.activeBlocknum = (ev.detail as bigint) + 1n
    const ce = this.tracker.Epoch(this.activeBlocknum)
    if (ce !== this.currentEpoch) {
      logger.Log(`EpochShift occured: ${this.currentEpoch} -> ${ce}`)
      this.currentEpoch = ce
      document.dispatchEvent(
        new CustomEvent("ErdstallEpochShift", {
          detail: { epoch: ce },
        })
      )
    }
    document.dispatchEvent(
      new CustomEvent("ErdstallProgress", {
        detail: this.tracker.Progress(this.activeBlocknum),
      })
    )
    document.dispatchEvent(new CustomEvent("ErdstallBalance"))
  }

  //Withdrawn returns whether a `Withdraw` event was registered with the given
  //`Balance` onchain.
  Withdrawn = async (balance: Balance): Promise<boolean> => {
    const begin = this.tracker.EpochToBlock(balance.epoch.valueOf())
    const events = await this.erdstall.queryFilter(
      this.erdstall.filters.Withdrawn(
        BigNumber.from(balance.epoch.toString()),
        balance.account.toString(),
        null
      ),
      begin
    )
    return events.length > 0
  }

  private onExitProof = (ep: BalanceProof): void => {
    if (ep.balance.account.toString() !== this.Address.toString()) {
      return
    }

    this.exitBP = ep
    this.Withdrawn(ep.balance).then((withdrawn) => {
      if (withdrawn) {
        return
      }

      if (
        this.valueOfBalance(this.offchainBals) >=
        this.valueOfBalance(ep.balance.values)
      ) {
        this.addOffchainBalance(-this.valueOfBalance(ep.balance.values))
      }
      this.withdrawableBals = ep.balance.values

      document.getElementById("es-maincolumn")!.dispatchEvent(
        new CustomEvent("ErdstallReceivedExitProof", {
          detail: { ep: BigInt(ep.balance.epoch.toString()) },
          bubbles: true,
        })
      )
    })
  }

  // onBalanceProof verifies a received `BalanceProof` and if successful updates
  // the local cache.
  private onBalanceProof = (bp: BalanceProof): void => {
    console.log("Received BalanceProof from ", bp.balance.account.toString())
    if (bp.balance.account.toString() !== this.Address.toString()) {
      return
    }

    this.balanceProofs.Push(bp)
    if (this.inRangeOf(bp.balance.epoch.valueOf(), this.dpEpoch, 1n, 1n)) {
      document.getElementById("es-maincolumn")!.dispatchEvent(
        new CustomEvent("ErdstallReceivedDepProof", {
          bubbles: true,
        })
      )
    }
    document.getElementById("es-maincolumn")!.dispatchEvent(
      new CustomEvent("ErdstallReceivedBalProof", {
        detail: { ep: bp.balance.epoch.valueOf() },
        bubbles: true,
      })
    )
    this.setOffchainBals(this.valueOfBalance(bp.balance.values))
    document.dispatchEvent(
      new CustomEvent("ErdstallBalanceUpdate", { bubbles: true })
    )
  }

  private inRangeOf(
    value: bigint,
    base: bigint,
    plus: bigint,
    minus: bigint
  ): boolean {
    return value >= base - minus || value === base || value <= base + plus
  }

  private valueOfBalance(balance: Assets): bigint {
    const asset = balance.values.get(this.token)
    if (!asset) {
      return 0n
    }

    switch (asset.typeTag()) {
      case "uint":
        return (asset as Amount).value
      default:
        throw "not implemented"
    }
  }

  // onTxReceipt handles incoming txReceipts and appends them to the txhistory.
  private onTxReceipt = (txr: TxReceipt) => {
    document.dispatchEvent(
      new CustomEvent("ErdstallBalanceUpdate", { bubbles: true })
    )
    switch (txr.tx.txType()) {
      case Transfer:
        return this.handleTransferReceipt(txr.tx as Transfer)
      default:
        console.error("Received unexpected TxReceipt.")
    }
  }

  private handleTransferReceipt(tx: Transfer) {
    const current_epoch = this.tracker.Epoch(this.activeBlocknum)
    console.log(`Received transfer receipt in epoch: ${current_epoch}`)
    const simpleTransfer: transaction.Transfer = {
      nonce: tx.nonce.valueOf(),
      epoch: current_epoch,
      sender: tx.sender.toString(),
      recipient: tx.recipient.toString(),
      amount: (tx.values.values.get(this.token) as Amount).value,
      sig: Signature.toJSON(tx.sig!),
    }
    document.dispatchEvent(
      new CustomEvent("ErdstallTXReceipt", { detail: simpleTransfer })
    )
    this.handleOffchainBalanceWithReceipt(tx)
  }

  private handleOffchainBalanceWithReceipt(tx: Transfer) {
    const myAddress = this.Address.toString()
    const senderAddress = tx.sender.toString()
    const recipientAddress = tx.recipient.toString()
    const txTargetsOthers = recipientAddress !== myAddress

    const iAmSender = senderAddress === myAddress
    if (iAmSender) {
      const amount = tx.values.values.get(this.token) as Amount
      this.subOffchainBalance(amount.value)
    }

    if (txTargetsOthers) {
      return
    } else {
      // I am target.
      const amount = tx.values.values.get(this.token) as Amount
      this.addOffchainBalance(amount.value)
    }

    document.dispatchEvent(
      new CustomEvent("ErdstallBalanceUpdate", { bubbles: true })
    )
  }

  // reset resets the client s.t. it is possible to start usage from a clean
  // plate.
  private reset = () => {
    this.balanceProofs.Clear()
    this.withdrawableBals = new Assets()
    this.exitRequested = false
    this.dpEpoch = 0n
  }
}

export const UninitializedConnErr = new Error("send on uninitialized link")
