// SPDX-License-Identifier: Apache-2.0

import { providers } from "ethers"
import * as logger from "./logger"

export class Watcher {
  private provider: providers.Web3Provider
  constructor(provider: providers.Web3Provider) {
    this.provider = provider
  }

  WatchBlocks = async () => {
    this.provider.on("block", (bn) => {
      logger.LogInfoWithField("Blocknr.")(bn)
      document.dispatchEvent(
        new CustomEvent("ErdstallBlock", { bubbles: true, detail: BigInt(bn) })
      )
    })
  }
}
