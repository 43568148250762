// SPDX-License-Identifier: Apache-2.0

import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import * as icons from "react-icons/md"

interface widgetProps {
  className: string
  logo: JSX.Element
  id?: string
  title: string
  children?: any
  leftHeader?: any
  rightHeader?: any
  info?: any
}

export default function ErdstallWidget(props: widgetProps) {
  const info = () => {
    if (props.info === undefined) {
      return <></>
    }
    return (
      <Col className="mt-3 justify-content-center">
        <Container className="d-flex justify-content-end" fluid>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="pmtp">{props.info}</Tooltip>}
          >
            <icons.MdInfo />
          </OverlayTrigger>
        </Container>
      </Col>
    )
  }

  return (
    <Container
      id={props.id}
      className={"erdstallwidget plane White-Text " + props.className}
      fluid
    >
      <Row noGutters>
        <Col className="mt-3 justify-content-center" md="auto">
          <span className="widgetHeader mr-1">{props.logo}</span>
        </Col>
        <Col>
          <Row>
            <Col className="mt-3 justify-content-center" md="auto">
              <span className="widgetHeader">{props.title}</span>
            </Col>
            {props.leftHeader}
            {props.rightHeader}
            {info()}
          </Row>
          <hr className="widgethr mt-1" />
        </Col>
      </Row>
      {props.children}
    </Container>
  )
}
