// SPDX-License-Identifier: Apache-2.0

// This file simple exports all required explanations for Erdstall. E.g. the
// intro text for the landingpage.

export const ErdstallIntro = (
  <>
    This is the Erdstall web3 client (<b>E3C</b>). <b>E3C</b> serves as a demo
    to showcase the potential onboarding process, subsequent off-chain
    transactions as well as the withdrawal process. Check out the presentation
    at the bottom, which we held at the ETHOnline finals. It might serve as a
    nice introduction to Erdstall and explain some important concepts on the
    way.
  </>
)

export const ErdstallOnboard = (
  <>
    Pressing <b className="p-1 roundedBox">Let me in!</b> will let you enter the
    Erdstall command center by connecting an Ethereum account with your
    web3-compatible browser. There, you are able to <b>deposit</b> and{" "}
    <b>withdraw</b> funds from the Erdstall contract and send off-chain
    transactions within the Erdstall ecosystem.
  </>
)

export const ErdstallOnboardLoading = (
  <>Fetching contract data, please be patient.</>
)

export const ErdstallInfoExit = (
  <p>
    The Enclave was now informed by your exit. The confirmation for this will
    take some time. The circle in the top right of this panel will show the
    exiting progress. As soon as the circle is filled, you will be able to
    withdraw your funds via a popup window.
  </p>
)

export const EmergencyWithdraw = (
  <p>
    Sorry for the inconvenience but the automatic withdraw call failed. To
    ensure you still are able to claim your precious funds, you were dropped to
    this emergency screen. This happens when the client (in this case <b>you</b>{" "}
    is a little bit ahead of time in regards to the current Epoch. In essence,
    your client expected the exit phase to be done already while, in reality, it
    was not. Simply wait a bit and press the button <b>I want my funds back</b>.
  </p>
)

export const StandardWithdraw = (val: bigint) => {
  return (
    <p>
      You received an <b>ExitProof</b> and are now able to withdraw your funds
      as soon es the <b>Epoch Meter</b> reached {val.toString()}.
    </p>
  )
}
