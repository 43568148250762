// SPDX-License-Identifier: Apache-2.0

import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { EtherScan } from "./etherscan"
import "./erdstallmodal.css"

interface props {
  Erdstall: string
  Token: string
  onToggle: () => void
  show: boolean
}

export function OperatorModal(props: props) {
  return (
    <Modal show={props.show} onHide={props.onToggle} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Operator-Configuration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {[
            ["Erdstall contract", props.Erdstall],
            ["Token contract", props.Token],
          ].map((vals: string[]) => {
            const [desc, address] = vals
            return (
              <>
                <Col md="4">{desc}:</Col>
                <Col className="justify-content-begin">
                  <EtherScan address={address} type="address" />
                </Col>
              </>
            )
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onToggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
